import React from 'react';
import { Button, Card, Empty, Typography } from 'antd';
import { Authorized } from 'components';
import styles from './AppEmpty.module.less';

interface IProps {
  authorized?: boolean;
  action?: () => void;
  actionText?: string;
}

export function AppEmpty({ action, actionText, authorized }: IProps) {
  const auth = authorized !== undefined ? authorized : true;
  return (
    <Card>
      <Empty
        className={styles.emptyContacts}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Typography.Text type="secondary"> No Data </Typography.Text>}
      >
        {action && actionText && (
          <Authorized authorized={auth}>
            <Button type="primary" onClick={action} disabled={!auth}>
              {actionText}
            </Button>
          </Authorized>
        )}
      </Empty>
    </Card>
  );
}

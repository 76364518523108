import { useCallback, useEffect, useState } from 'react';
import { useNAndQTopicsQuery } from '@queries/question';
import { FieldSelectOption } from 'types';
import { searchStringInArray } from 'utils/strUtils';

export const useGetQuestionTopics = () => {
  const { data } = useNAndQTopicsQuery();

  const [customTopicOptions, setCustomTopicOptions] = useState<FieldSelectOption[]>([]);
  const [topicOptions, setTopicOptions] = useState<FieldSelectOption[]>([]);
  useEffect(() => {
    if (data?.nAndQTopics) {
      setTopicOptions((data?.nAndQTopics || []).map(item => ({ label: item, value: item })));
    }
  }, [data?.nAndQTopics]);

  const onSearchTopics = useCallback((value: string) => {
    if (value && !searchStringInArray([], value)) {
      setCustomTopicOptions([{ label: value, value: value }]);
    } else {
      setCustomTopicOptions([]);
    }
  }, []);

  return {
    onSearchTopics,
    topicsList: [...customTopicOptions, ...topicOptions]
  };
};

import { AppDateFormats } from '../types';
import dayjs from 'dayjs';

class DateTimeUtils {
  toPacificTimezoneString = (date?: dayjs.ConfigType, format?: AppDateFormats, dateOnly?: boolean): string | null => {
    let newDate = dayjs(date);
    if (dateOnly) {
      newDate = newDate.add(12, 'hour');
    }
    return date && newDate.isValid() ? newDate.tz().format(format) : null;
  };

  toUTCString = (date?: dayjs.ConfigType, format?: AppDateFormats): string | null => {
    const newDate = dayjs(date);
    return date && newDate.isValid() ? newDate.utc().format(format) : null;
  };

  toLocaleString = (date?: dayjs.ConfigType, format?: AppDateFormats): string | null => {
    const newDate = dayjs(date);
    return date && newDate.isValid() ? newDate.format(format) : null;
  };

  toPacificTimezoneObject = (date?: dayjs.ConfigType, dateOnly?: boolean) => {
    let newDate = dayjs(date);
    if (dateOnly) {
      newDate = newDate.add(12, 'hour');
    }
    return newDate.isValid() ? newDate.tz() : null;
  };

  toUTCMonthAndTime(date?: dayjs.ConfigType) {
    return date ? dayjs(date).startOf('month').format(AppDateFormats.ISO8601Date) + 'T00:00:00.000Z' : undefined;
  }
}

export const dateTimeUtils = new DateTimeUtils();

import { InMemoryCacheConfig } from '@apollo/client';

export const accountTypePolicy: InMemoryCacheConfig = {
  typePolicies: {
    Account: {
      fields: {
        salesforceAccountHistories: {
          keyArgs: false
        },
        salesforceContactHistories: {
          keyArgs: false
        }
      }
    }
  }
};

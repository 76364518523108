import { InMemoryCacheConfig } from '@apollo/client';

export const queryTypePolicy: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        client: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Client',
              id: args!.id
            });
          }
        },
        user: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'User',
              id: args!.id
            });
          }
        },
        salesforceContact: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Contact',
              id: args!.id
            });
          }
        },
        financialBundle: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'FinancialBundle',
              id: args!.id
            });
          }
        },
        ventureCapitalFirm: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'VentureCapitalFirm',
              id: args!.id
            });
          }
        },
        serviceRateChange: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ServiceRateChange',
              id: args!.id
            });
          }
        },
        clientSpecificProcedure: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ClientSpecificProcedure',
              id: args!.id
            });
          }
        },
        kruzeVideoTip: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'KruzeVideoTip',
              id: args!.id
            });
          }
        },
        kruzeBlogPostItem: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'KruzeBlogPostItem',
              id: args!.id
            });
          }
        },
        clientReport: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ClientReport',
              id: args!.id
            });
          }
        },
        clientChart: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ClientChart',
              id: args!.clientChartId
            });
          }
        },
        clients: {
          keyArgs: ['filter']
        },
        users: {
          keyArgs: ['search']
        },
        taxPapers: {
          keyArgs: ['clientId']
        },
        rAndDReports: {
          keyArgs: false
        },
        clientReports: {
          keyArgs: ['filter']
        },
        clientAnalyses: {
          keyArgs: false
        },
        keeperRuleSets: {
          keyArgs: false
        },
        vendorAnalyses: {
          keyArgs: false
        }
      }
    }
  }
};

/*
function customRelayPaginationWithLastPageInfo(keyArgs?: any) {
  const obj = relayStylePagination(keyArgs);
  return {
    ...obj,
    merge: (existing: any, incoming: any, rest: any) => {
      let data = (obj as any).merge(existing, incoming, rest);
      return {
        ...data,
        pageInfo: incoming.pageInfo
      };
    }
  };
}
*/

import React from 'react';
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/es/popconfirm';

interface IPopConfirmWrapperProps {
  id?: any;
  onConfirm: (id?: any) => any;
  title: string | React.ReactNode;
  okText: string;
  isDanger?: boolean;
  popConfirmAttr?: Omit<PopconfirmProps, 'title' | 'okText' | 'onConfirm'>;
  children: string | React.ReactNode;
}

export const PopConfirmWrapper: React.FC<IPopConfirmWrapperProps> = React.memo(
  ({ id, onConfirm, title, okText, isDanger, children, popConfirmAttr }: IPopConfirmWrapperProps) => {
    const { placement, icon, okButtonProps = {}, ...rest } = popConfirmAttr || {};
    const action = () => {
      if (id) {
        onConfirm(id);
      } else {
        onConfirm();
      }
    };

    return (
      <Popconfirm
        placement={placement ?? 'topRight'}
        icon={icon ?? false}
        title={title}
        okText={okText}
        onConfirm={action}
        okButtonProps={{
          danger: !!isDanger,
          ...okButtonProps
        }}
        {...rest}
      >
        {children}
      </Popconfirm>
    );
  }
);

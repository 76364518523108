import React from 'react';
import { Navigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { AppSpin } from 'components';
import styles from './styles/ConnectToQbo.module.less';
import { useIsAuthenticated } from 'hooks';
import { useConnectQboClient } from 'hooks/useConnectQboClient';

function ConnectToQbo() {
  const isAuthenticated = useIsAuthenticated();
  useConnectQboClient();

  return isAuthenticated ? (
    <Row className={styles.connectQbo}>
      <Col span={24} style={{ height: '100vh' }}>
        <AppSpin />
      </Col>
    </Row>
  ) : (
    <Navigate to="/" replace={true} />
  );
}

export default ConnectToQbo;

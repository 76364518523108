import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import styles from './styles/SuccessButton.module.less';
import cs from 'classnames';

interface IProps extends ButtonProps {
  className?: string;
  children: React.ReactNode;
}

export function SuccessButton({ children, className, ghost, ...props }: IProps) {
  return (
    <Button
      className={cs({
        [styles.successGhostButton]: ghost,
        [styles.successButton]: !ghost,
        [className!]: !!className
      })}
      ghost={!!ghost}
      {...props}
    >
      {children}
    </Button>
  );
}

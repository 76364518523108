import React from 'react';
import styles from './AppPaper.module.less';
import cs from 'classnames';

interface IAppPaperProps {
  children: React.ReactNode;
  className?: string;
}

export function AppPaper({ children, className }: IAppPaperProps) {
  return <div className={className ? cs([styles.appPaper, className]) : styles.appPaper}>{children}</div>;
}

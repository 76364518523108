import React, { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { SelectProps } from 'antd';
import { AppSpin, FieldSelect } from 'components';
import { useDebounceSearchUser } from 'hooks';
import { FieldSelectOption } from 'types';

interface IProps {
  control: Control<any>;
  name: string;
  label?: string;
  ariaLabel?: string;
  labelField?: 'name' | 'email';
  defaultOptionsList?: FieldSelectOption[];
  selectProps?: Omit<SelectProps, 'onSearch'>;
  customFunctionOnSelect?: (selectedUserName: string) => void;
}

export function AppDebounceSearchClientUser({
  control,
  name,
  label,
  labelField,
  defaultOptionsList,
  selectProps,
  ariaLabel,
  customFunctionOnSelect
}: IProps) {
  const { options, loading, onDebounceSearch } = useDebounceSearchUser(defaultOptionsList, labelField);
  const loadingComponent = useMemo(() => <AppSpin size="small" />, []);

  const getUserNameFromId = (selectedUserId: string) => {
    if (customFunctionOnSelect) {
      customFunctionOnSelect(
        options.find((option: { value: string; label: string }) => option.value === selectedUserId).label
      );
    }
  };

  return (
    <FieldSelect
      control={control}
      name={name}
      showSearch
      filterOption={false}
      onSearch={onDebounceSearch}
      onSelect={getUserNameFromId}
      notFoundContent={loading ? loadingComponent : null}
      optionsList={options}
      label={label}
      ariaLabel={ariaLabel}
      {...selectProps}
    />
  );
}

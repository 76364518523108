import {
  GlSuggestionQuery,
  GlSuggestionQueryVariables,
  VendorReportQuery,
  VendorReportQueryVariables,
  VendorReportsQuery,
  VendorReportsQueryVariables
} from '@queries/vendor.generated';
import { gql } from '@apollo/client';
import { VENDOR_FRAGMENT } from '@fragments/vendor';
import { apolloLazyQueryHookGenerator, apolloQueryHookGenerator } from 'utils/hookGenerator';

export const VENDOR_REPORTS = gql`
  ${VENDOR_FRAGMENT}
  query VendorReports($page: Int, $per: Int) {
    vendorReports(page: $page, per: $per) {
      data {
        ...VendorFields
      }
      currentPage
      per
      totalCount
      totalPages
    }
  }
`;

export const VENDOR_REPORT = gql`
  ${VENDOR_FRAGMENT}
  query VendorReport($id: ID!) {
    vendorReport(id: $id) {
      ...VendorFields
    }
  }
`;
export const GL_SUGGESTION = gql`
  query GlSuggestion($field: SuggestionFields!, $query: String!) {
    glSuggestion(field: $field, query: $query)
  }
`;

export const useVendorReportsQuery = apolloQueryHookGenerator<VendorReportsQuery, VendorReportsQueryVariables>(
  VENDOR_REPORTS,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useVendorReportQuery = apolloQueryHookGenerator<VendorReportQuery, VendorReportQueryVariables>(
  VENDOR_REPORT,
  {
    notifyOnNetworkStatusChange: true
  }
);

export const useGlSuggestionLazyQuery = apolloLazyQueryHookGenerator<GlSuggestionQuery, GlSuggestionQueryVariables>(
  GL_SUGGESTION,
  {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  }
);

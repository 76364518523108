import { useCallback, useState } from 'react';

type ResponseType<T = unknown> = { data: T; error: IError | null; loading: boolean };

type State<T = unknown> = [makeRequest: (data?: any, init?: RequestInit) => unknown, response: ResponseType<T>];

const DEFAULT_REQUEST_OPTIONS = {
  cache: 'no-cache'
};

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
};

interface IError {
  status?: number;
  statusText?: string;
  message?: any;
}

export function useLazyFetch<T = unknown>(input: RequestInfo, initData?: RequestInit) {
  const [execution, setExecution] = useState<ResponseType<T>>({} as ResponseType<T>);
  const [init] = useState(() => initData); //Cache Data

  const requestMaker = useCallback(
    async (data?: {} | null, additionalInit?: RequestInit) => {
      setExecution(prev => ({ ...prev, loading: true, error: null }));
      let initData = {
        credentials: 'include',
        method: 'GET',
        ...DEFAULT_REQUEST_OPTIONS,
        ...init,
        ...additionalInit,
        headers: {
          ...DEFAULT_HEADERS,
          ...init?.headers,
          ...additionalInit?.headers
        },
        ...(!!data ? { body: JSON.stringify(data) } : {})
      } as RequestInit;

      try {
        let response = await fetch(input, initData);
        const responseData = await response.json();
        if (response.ok) {
          setExecution(prev => ({ ...prev, data: responseData, loading: false }));
        } else {
          setExecution(prev => ({
            ...prev,
            error: {
              status: response.status,
              statusText: response.statusText,
              message: responseData
            },
            loading: false
          }));
        }
      } catch (e) {
        setExecution(prev => ({
          ...prev,
          error: {
            message: e
          },
          loading: false
        }));
        throw new Error('Request Failed');
      }
    },
    [input, init]
  );

  return [requestMaker, execution] as State<T>;
}

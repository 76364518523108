import { useEffect, useMemo, useRef, useState } from 'react';
import { SuggestionFields } from 'apollo/generatedSchema';
import { useGlSuggestionLazyQuery } from '@queries/vendor';
import { debounce as _debounce } from 'lodash';

const queryAmountDataTransform = (data: any): { value: any; label: string } | null => {
  if (data?.glSuggestion) {
    try {
      const jsonData = JSON.parse(data.glSuggestion);
      return {
        value: jsonData.query,
        label: `${jsonData.query} matched (${jsonData.amount}) transactions`
      };
    } catch (e) {
      //
    }
  }
  return null;
};

const queryArrayDataTransform = (data: any): [{ value: any; label: string }] | null => {
  if (data?.glSuggestion) {
    try {
      const jsonData = JSON.parse(data.glSuggestion);
      if (jsonData.length) {
        return jsonData.map((item: string) => ({
          value: item,
          label: item
        }));
      }
    } catch (e) {
      //
    }
  }
  return null;
};

export function useDebounceSearchGlSuggestion(field: SuggestionFields) {
  const abortC = useRef<AbortController>();

  const [getSuggestion, { data, loading }] = useGlSuggestionLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [options, setOptions] = useState<any>([]);

  const onDebounceSearch = useMemo(() => {
    return _debounce((value: string) => {
      if (field && value) {
        setOptions([]);
        if (abortC.current instanceof AbortController) {
          abortC.current.abort();
        }
        abortC.current = new AbortController();
        getSuggestion({
          variables: {
            field,
            query: value
          },
          context: {
            fetchOptions: {
              signal: abortC.current.signal
            }
          }
        });
      }
    }, 800);
  }, [getSuggestion, field]);

  useEffect(() => {
    switch (field) {
      case SuggestionFields.VendorWordsOnly:
      case SuggestionFields.DescriptionWordsOnly:
      case SuggestionFields.AccountWordsOnly: {
        const options = queryAmountDataTransform(data);
        if (options) {
          setOptions([options]);
        }
        break;
      }
      case SuggestionFields.VendorSuggestion:
      case SuggestionFields.TransactionSuggestion:
      case SuggestionFields.ClientNameSuggestion: {
        const options = queryArrayDataTransform(data);
        if (options) {
          setOptions(options);
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [data, field]);

  return {
    options,
    loading,
    onDebounceSearch
  };
}

import {
  CurrentUserInfoQuery,
  CurrentUserInfoQueryVariables,
  UserQuery,
  UserQueryVariables,
  UserRolesQuery,
  UserRolesQueryVariables,
  UsersQuery,
  UsersQueryVariables
} from '@queries/user.generated';
import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { ALL_USER_FIELDS_FRAGMENT } from '@fragments/user';
import { apolloLazyQueryHookGenerator, apolloQueryHookGenerator } from 'utils/hookGenerator';

export const USERS = gql`
  ${ALL_USER_FIELDS_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  query Users(
    $search: String
    $status: UsersFilter
    $page: Int
    $per: Int
    $role: String
    $withPhoto: Boolean = true
    $withDeactivatedAt: Boolean = false
    $withRoles: Boolean = false
  ) {
    users(search: $search, status: $status, page: $page, per: $per, role: $role) {
      data {
        ...AllUserFields
        photo @include(if: $withPhoto) {
          ...AttachmentFields
        }
        deactivatedAt @include(if: $withDeactivatedAt)
        roles @include(if: $withRoles)
      }
      currentPage
      per
      totalCount
      totalPages
    }
  }
`;

export const USER = gql`
  ${ALL_USER_FIELDS_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  query User($id: ID!, $withPhoto: Boolean = true, $withDeactivatedAt: Boolean = false, $withRoles: Boolean = false) {
    user(id: $id) {
      ...AllUserFields
      photo @include(if: $withPhoto) {
        ...AttachmentFields
      }
      deactivatedAt @include(if: $withDeactivatedAt)
      roles @include(if: $withRoles)
    }
  }
`;

export const useUsersLazyQuery = apolloLazyQueryHookGenerator<UsersQuery, UsersQueryVariables>(USERS);

export const useUserLazyQuery = apolloLazyQueryHookGenerator<UserQuery, UserQueryVariables>(USER);
export const useUserQuery = apolloQueryHookGenerator<UserQuery, UserQueryVariables>(USER, {
  notifyOnNetworkStatusChange: true
});

export const USER_ROLES = gql`
  query UserRoles($id: ID!) {
    user(id: $id) {
      id
      roles
    }
  }
`;

export const useUserRolesQuery = apolloQueryHookGenerator<UserRolesQuery, UserRolesQueryVariables>(USER_ROLES, {
  notifyOnNetworkStatusChange: true
});

export const CURRENT_USER_INFO = gql`
  ${ATTACHMENT_FRAGMENT}
  query CurrentUserInfo($filter: NotificationsFilter, $clientId: ID, $per: Int, $page: Int) {
    currentUser {
      id
      email
      name
      photo {
        ...AttachmentFields
      }
      notifications(filter: $filter, clientId: $clientId, per: $per, page: $page) {
        currentPage
        per
        totalCount
        totalPages
        data {
          client {
            id
            name
            logo {
              ...AttachmentFields
            }
          }
          content
          createdAt
          id
          isDone
          kind
          title
          updatedAt
        }
      }
    }
  }
`;

export const useCurrentUserInfoQuery = apolloQueryHookGenerator<CurrentUserInfoQuery, CurrentUserInfoQueryVariables>(
  CURRENT_USER_INFO,
  {
    notifyOnNetworkStatusChange: true
  }
);

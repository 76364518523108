import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { NOTIFICATIONS_FRAGMENT } from '@fragments/notifications';

export const ALL_USER_FIELDS_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  ${NOTIFICATIONS_FRAGMENT}
  fragment AllUserFields on User {
    id
    name
    email
    photo {
      ...AttachmentFields
    }
    notifications {
      ...NotificationsFields
    }
    role
    deactivatedAt
  }
`;

import React, { useMemo } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FontSizeOutlined } from '@ant-design/icons';
import styles from './styles/DraftEditor.module.less';
import cs from 'classnames';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { pureText } from 'utils/strUtils';

const options = {
  entityStyleFn: entity => {
    const entityType = entity.get('type').toLowerCase();
    if (entityType === 'link') {
      const data = entity.getData();
      return {
        element: 'a',
        attributes: {
          href: data.url,
          target: '_blank'
        }
      };
    }
  }
};

function DraftEditor({ value, onChange, className, allToolbarOption = false, toolbarOptions = null }) {
  const [editorState, setEditorState] = React.useState(() => {
    return value ? EditorState.createWithContent(stateFromHTML(value)) : EditorState.createEmpty();
  });

  const additionalOptions = useMemo(() => {
    const defaultToolbarOptions = {
      options: ['inline', 'fontSize', 'link', 'list', 'history'],
      inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'strikethrough', 'underline']
      },
      link: {
        inDropdown: false,
        options: ['link'],
        popupClassName: styles.linkPopup,
        defaultTargetOption: '_blank'
      },
      fontSize: {
        icon: <FontSizeOutlined />,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
      }
    };
    return allToolbarOption
      ? {}
      : {
          toolbar: toolbarOptions !== null ? toolbarOptions : defaultToolbarOptions
        };
  }, [allToolbarOption, toolbarOptions]);

  const handleOnEditorStateChange = e => {
    setEditorState(e);
    const html = stateToHTML(editorState.getCurrentContent(), options);
    onChange(pureText(html).trim().length ? html : '');
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        editorClassName={cs(styles.editor, {
          [className]: !!className
        })}
        toolbarClassName={styles.toolbar}
        onEditorStateChange={handleOnEditorStateChange}
        {...additionalOptions}
      />
    </div>
  );
}

export default DraftEditor;

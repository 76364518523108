import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { Attachment } from 'apollo/generatedSchema';

interface IProps {
  attachments: Attachment[];
}

export function Attachments({ attachments }: IProps) {
  const items: MenuProps['items'] = attachments.map((item, index) => ({
    key: index,
    label: (
      <a target="_blank" href={item!.url} download rel="noreferrer">
        {item.fileName}
      </a>
    )
  }));

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button>
        <Space>
          Attachments
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}

import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { NOTIFICATIONS_FRAGMENT } from '@fragments/notifications';

export const VENDOR_ANALYSIS_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  ${NOTIFICATIONS_FRAGMENT}
  fragment VendorAnalysisFields on VendorAnalysis {
    createdAt
    id
    name
    updatedAt
    user {
      notifications {
        ...NotificationsFields
      }
      id
      email
      photo {
        ...AttachmentFields
      }
    }
    vendors
    vendorsCount
  }
`;

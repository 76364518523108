import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import styles from './styles/LinkButton.module.less';
import cs from 'classnames';

interface IProps extends Omit<ButtonProps, 'type'> {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export function LinkButton({ className, children, ...buttonProps }: IProps) {
  return (
    <Button type="link" className={cs(styles.linkButton, { [className!]: !!className })} {...buttonProps}>
      {children}
    </Button>
  );
}

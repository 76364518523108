import { gql } from '@apollo/client';

export const HISTORY_FRAGMENT = gql`
  fragment HistoryFields on History {
    action
    createdAt
    historyableType
    id
    updatedAt
    user {
      id
      email
      name
    }
  }
`;

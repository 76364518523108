import { useEffect, useState } from 'react';
import { Grid } from 'antd';
import { AppBreakpoints, sortedBreakpoints } from 'types';

const { useBreakpoint } = Grid;

export function useGetCurrentBreakpoint(): AppBreakpoints {
  const screens = useBreakpoint();
  const [breakpoint, setBreakpoint] = useState<AppBreakpoints>('sm');

  useEffect(() => {
    function findExactBreakpoint(): AppBreakpoints {
      let data: AppBreakpoints = 'sm';
      for (let i = 0; i < sortedBreakpoints.length; i++) {
        if (!!screens[sortedBreakpoints[i]]) {
          data = sortedBreakpoints[i];
          break;
        }
      }
      return data;
    }

    if ((Object.keys(screens) || []).length) {
      setBreakpoint(findExactBreakpoint());
    }
  }, [screens]);

  return breakpoint;
}

import React from 'react';
import { Space, Typography } from 'antd';
import { HistoryAction } from 'apollo/generatedSchema';
import styles from './QuestionHistory.module.less';
import { AppDivider } from '../appDivider/AppDivider';

interface IProps {
  author?: string;
  action?: HistoryAction;
  actionDate?: string;
}

function HistoryActionComponent({ author, action, actionDate }: IProps) {
  return (
    <AppDivider orientation="center" topSpace={5} bottomSpace={5}>
      <Space split={<AppDivider type="vertical" />} size="small">
        <Typography.Text type="secondary" className={styles.dividerText}>
          {author}
        </Typography.Text>
        <Typography.Text type="secondary" className={styles.dividerText}>
          {action}
        </Typography.Text>
        <Typography.Text type="secondary" className={styles.dividerText}>
          {actionDate}
        </Typography.Text>
      </Space>
    </AppDivider>
  );
}

export default React.memo(HistoryActionComponent);

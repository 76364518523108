import { useCallback } from 'react';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { MutationErrors, ValidationError } from 'apollo/generatedSchema';
import { useNotifications } from './useNotifications';

export const useApiErrorHandler = () => {
  const { openNotification } = useNotifications();

  return useCallback(
    (errors?: MutationErrors | null, formErrorCallback?: UseFormSetError<any>) => {
      const { genericError, validationErrors } = errors || {};
      if (genericError) {
        openNotification({
          message: 'Error',
          description: genericError,
          actionType: 'error'
        });
      } else if (validationErrors?.length) {
        if (formErrorCallback) {
          try {
            validationErrors?.forEach((error: ValidationError) => {
              formErrorCallback(error.field, {
                message: error.message
              });
            });
          } catch (e) {
            //
          }
        }
        openNotification({
          message: 'Error',
          description: 'Validation Errors!',
          actionType: 'error'
        });
      }
      return genericError || validationErrors?.length;
    },
    [openNotification]
  );
};

import React from 'react';
import { Page500Component } from 'components/appErrorPages/Page500Component';
import * as Sentry from '@sentry/react';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false } as any;
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (['production', 'staging'].includes(process.env.REACT_APP_DEPLOY_ENV as string)) {
      Sentry.withScope(scope => {
        scope.setExtras({ errInfo: info });
        Sentry.captureException(error);
      });
    }
  }

  static getDerivedStateFromError(error: any) {
    if (process.env.REACT_APP_DEPLOY_ENV !== 'dev') {
      return { hasError: true };
    }
  }

  render() {
    const { hasError } = this.state;

    return hasError ? <Page500Component /> : <>{this.props.children}</>;
  }
}

export default ErrorBoundary;

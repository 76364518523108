export function getLocalStorageItem<T>(key: string): T | undefined {
  // Prevent build error "window is undefined" but keep working
  if (typeof window === 'undefined') {
    return undefined;
  }
  try {
    const item = localStorage.getItem(key);
    return item ? (parseJSON(item) as T) : undefined;
  } catch (error) {
    return undefined;
  }
}

export function setLocalStorageItem<T>(key: string, value: T): boolean {
  // Prevent build error "window is undefined" but keeps working
  if (typeof window == 'undefined') {
    return false;
  }
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    //
  }
  return false;
}

export function removeLocalStorageItem(key: string): void {
  if (typeof window !== 'undefined') {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      //
    }
  }
}

// A wrapper for "JSON.parse()"" to support "undefined" value
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch (error) {
    return undefined;
  }
}

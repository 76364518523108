import React from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import { AppColoredTitle, Authorized, LinkButton, PrimaryGhostButton } from 'components';
import { addToDrawerHandler } from '@reactiveVars/addToDrawer';
import styles from './AppPageTitle.module.less';
import cs from 'classnames';

interface IProps {
  title: string | React.ReactNode;
  level?: TitleProps['level'];
  color?: 'default' | 'blue';
  actions?: (
    | {
        actionText: string;
        authorized?: boolean;
        onClick: (...args: any) => any;
      }
    | React.ReactNode
  )[];
  className?: string;
  clientId?: string;
  isLoading?: boolean;
  isClientPage?: boolean;
}

export const AppPageTitle = React.memo(
  ({ title, level, color, actions, className, clientId, isLoading, isClientPage }: IProps) => {
    return (
      <Col
        span={24}
        className={cs(
          styles.pageHeader,
          {
            [className!]: !!className
          },
          { [styles.clientHeader]: isClientPage }
        )}
      >
        {typeof title === 'string' ? (
          <AppColoredTitle color={color ?? 'default'} level={!!level ? level : 2} className={styles.pageTitle}>
            {title}
          </AppColoredTitle>
        ) : (
          title
        )}

        {!!(actions || []).length && (
          <Space align="end">
            {(actions || []).map((action: any, index: number) => {
              const authorized = action?.authorized !== undefined ? action?.authorized : true;
              return action?.actionText ? (
                <Authorized key={action.actionText} authorized={authorized} placement="top">
                  <Row gutter={[10, 0]}>
                    {clientId && (
                      <Col>
                        <PrimaryGhostButton
                          onClick={() => addToDrawerHandler({ open: true, id: clientId })}
                          disabled={!authorized}
                        >
                          Add to
                        </PrimaryGhostButton>
                      </Col>
                    )}
                    <Col>
                      <Button
                        type="primary"
                        onClick={action.onClick}
                        disabled={!authorized}
                        loading={isLoading}
                        aria-label="custom-action-label"
                      >
                        {action.actionText}
                      </Button>
                    </Col>
                  </Row>
                </Authorized>
              ) : (
                <React.Fragment key={index}>{action}</React.Fragment>
              );
            })}
          </Space>
        )}
      </Col>
    );
  }
);

import { gql } from '@apollo/client';

export const KEEPER_QUALIFIER_FRAGMENT = gql`
  fragment KeeperQualifierFields on KeeperQualifier {
    name
    order
    origin
    score
  }
`;

export const KEEPER_RULE_SET_FRAGMENT = gql`
  fragment KeeperRuleSetFields on KeeperRuleSet {
    client {
      id
      name
    }
    enabledRulesCount
    endDate
    id
    startDate
    updatedAt

    synced
    syncProgress
    generateRulesUrl
  }
`;

export const KEEPER_RULE_FRAGMENT = gql`
  ${KEEPER_QUALIFIER_FRAGMENT}
  fragment KeeperRuleFields on KeeperRule {
    account
    enabled
    id
    name
    finalScore
    qualifiers {
      ...KeeperQualifierFields
    }
    vendorName
  }
`;

import React from 'react';
import { FieldError } from 'react-hook-form';
import { ExcelTypes, FileUpload, ImageTypes, PdfType } from '../types';

export const getFieldValidationErrors = (error: FieldError, field: string, label?: string | React.ReactNode) => {
  let errors: string[] = [];
  if (error) {
    if (error.types) {
      ((Object.values(error.types || {}) || []) as string[]).forEach(item => {
        if (typeof label === 'string' && item.indexOf(field) === 0) {
          errors.push(item.replace(field, label));
        } else {
          errors.push(item);
        }
      });
    } else if (error.message) {
      if (typeof label === 'string' && error.message.indexOf(field) === 0) {
        errors.push(error.message.replace(field, label));
      } else {
        errors.push(error.message);
      }
    }
  }

  return errors;
};

export const validateImageFormat = (required?: boolean) => {
  return (value: FileUpload | undefined) => {
    if (!(value?.fileList || []).length && required) {
      return false;
    }
    if (value?.file.type) {
      return [ImageTypes.JPG, ImageTypes.JPEG, ImageTypes.PNG].findIndex(item => item === value.file.type) !== -1;
    }
    return true;
  };
};

export const validatePdfFormat = (required?: boolean) => {
  return (value: FileUpload | undefined) => {
    if (!(value?.fileList || []).length && required) {
      return false;
    }
    if (value?.file.type) {
      return value.file.type === PdfType;
    }
    return true;
  };
};

export const validateXLSFormat = (required?: boolean) => {
  return (value: FileUpload | undefined) => {
    if (!(value?.fileList || []).length && required) {
      return false;
    }
    if (value?.file.type) {
      return [ExcelTypes.XLS, ExcelTypes.XLSX].findIndex(item => item === value.file.type) !== -1;
    }
    return true;
  };
};

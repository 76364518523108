import { createRoot } from 'react-dom/client';
import ErrorBoundary from 'components/ErrorBoundary';
import 'index.less';
import './dayjsGlobal';
import * as Sentry from '@sentry/react';
import App from 'App';
import ApolloStoreProvider from 'providers/ApolloStoreProvider';
import RouteProvider from 'providers/RouteProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_DEPLOY_ENV,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [process.env.REACT_APP_BACKEND as string]
    }),
    new Sentry.Feedback({ colorScheme: 'light' })
  ],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  // enabled: ['production', 'staging'].includes(process.env.REACT_APP_DEPLOY_ENV as string),
  tracesSampleRate: 0.25
});

root.render(
  <ApolloStoreProvider>
    <RouteProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </RouteProvider>
  </ApolloStoreProvider>
);

import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { NOTIFICATIONS_FRAGMENT } from '@fragments/notifications';

export const VENDOR_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  ${NOTIFICATIONS_FRAGMENT}
  fragment VendorFields on Vendor {
    accounts
    accountsToExclude
    clients {
      name
      id
    }
    createdAt
    descriptions
    descriptionsToExclude
    endDate
    error
    id
    keywords
    link
    names
    startDate
    status
    total
    transactionTypes
    updatedAt
    user {
      notifications {
        ...NotificationsFields
      }
      id
      photo {
        ...AttachmentFields
      }
      email
    }
  }
`;

import { ReactNode } from 'react';
import { inMemoryCacheObject, splitLink, typeDefs } from 'apollo';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  name: 'Kruze Control',
  version: '1.0.0',
  connectToDevTools: process.env.REACT_APP_DEPLOY_ENV !== 'production',
  link: splitLink,
  cache: inMemoryCacheObject,
  typeDefs
});

interface IProps {
  children: ReactNode;
}

export default function ApolloStoreProvider({ children }: IProps) {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

import { useEffect, useMemo, useRef, useState } from 'react';
import { useKeeperRuleCalculateScoreQuery } from '@queries/keeper';
import { debounce as _debounce } from 'lodash';

export function useDebounceGetQualifierScore(keeperRuleId: string) {
  const abortC = useRef<AbortController>();
  const [calculateScore, { data, loading }] = useKeeperRuleCalculateScoreQuery({
    fetchPolicy: 'no-cache'
  });

  const [score, setScore] = useState<number>();

  const onDebounceSearch = useMemo(() => {
    return _debounce((value: string) => {
      if (value) {
        setScore(0);
        if (abortC.current instanceof AbortController) {
          abortC.current.abort();
        }
        abortC.current = new AbortController();
        calculateScore({
          variables: {
            qualifier: value,
            keeperRuleId
          },
          context: {
            fetchOptions: {
              signal: abortC.current.signal
            }
          }
        });
      }
    }, 800);
  }, [calculateScore, keeperRuleId]);

  useEffect(() => {
    setScore(data?.keeperRuleCalculateScore);
  }, [data]);

  return {
    score,
    loading,
    onDebounceSearch
  };
}

import { FieldSelectOption } from './fields';

export type InfoType = 'success' | 'info' | 'warning' | 'error';

export enum BooleanEnum {
  YES = 'Yes',
  NO = 'No'
}

export const BOOLEAN_OPTIONS: FieldSelectOption[] = [
  {
    label: BooleanEnum.YES,
    value: true
  },
  {
    label: BooleanEnum.NO,
    value: false
  }
];

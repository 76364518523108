import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';

export const ANSWER_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  fragment AnswerFields on Answer {
    attachments {
      ...AttachmentFields
    }
    body
    createdAt
    id
    updatedAt
    clientPortalUser {
      id
      fullName
      email
      photo {
        ...AttachmentFields
      }
    }
  }
`;

import React from 'react';
import { Comment as AntdComment } from '@ant-design/compatible';
import { Typography } from 'antd';
import { Attachments } from 'components';
import type { Answer, QuestionComment } from 'apollo/generatedSchema';
import styles from './QuestionHistory.module.less';
import { AppDateFormats } from 'types';
import { dateTimeUtils } from 'utils/dateTimeUtils';

interface IProps {
  answer?: Partial<Answer>;
  comment?: Partial<QuestionComment>;
}

function HistoryComment({ answer, comment }: IProps) {
  const record = answer ? answer : comment;

  const userName = answer ? answer?.clientPortalUser?.fullName ?? '' : comment?.user?.name ?? '';
  const avatar = answer ? answer?.clientPortalUser?.photo?.url ?? '' : comment?.user?.photo?.url ?? '';

  const content = (
    <div>
      <div dangerouslySetInnerHTML={{ __html: record?.body as string }}></div>
      {!!record?.attachments?.length && <Attachments attachments={record.attachments} />}
    </div>
  );

  const author = (
    <Typography.Text strong className={styles.commentAuthor}>
      {userName}
    </Typography.Text>
  );

  return (
    <React.Fragment>
      <AntdComment
        className={styles.comment}
        author={author}
        avatar={avatar}
        content={content}
        datetime={
          dateTimeUtils.toPacificTimezoneString(record?.updatedAt, AppDateFormats.AppStandardDateMonth) as string
        }
      />
    </React.Fragment>
  );
}

export default React.memo(HistoryComment);

import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { ALL_USER_FIELDS_FRAGMENT } from '@fragments/user';

export const CLIENT_CARD_FRAGMENT = gql`
  ${ALL_USER_FIELDS_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  fragment ClientCard on Client {
    id
    name
    deactivated
    logo {
      ...AttachmentFields
    }
    manager {
      ...AllUserFields
    }
    taxContact {
      ...AllUserFields
    }
    companyUrl
    serviceRate
    billPayInformation {
      id
      monthlyFee
    }
    salesforceAccount {
      id
    }
    qboStatus
  }
`;

export const CLIENT_EDIT_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  fragment ClientEdit on Client {
    id
    logo {
      ...AttachmentFields
    }
    name
    companyUrl
    questionAndAnswerSheet
    consolidations
    deactivated
    disableVendorReport
    kruzeKeeperEnabled
    defaultReviewer {
      id
      reviewer1 {
        id
        name
      }
      reviewer2 {
        id
        name
      }
      reviewer3 {
        id
        name
      }
    }
    manager {
      id
    }
    taxContact {
      id
    }
    equityManagementPlatform
  }
`;

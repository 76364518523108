import { InMemoryCacheConfig } from '@apollo/client';

export const clientAnalysisTypePolicy: InMemoryCacheConfig = {
  typePolicies: {
    ClientAnalysis: {
      fields: {
        revenue: {
          keyArgs: false
        }
      }
    }
  }
};

import {
  KeeperRuleCalculateScoreQuery,
  KeeperRuleCalculateScoreQueryVariables,
  KeeperRuleQuery,
  KeeperRuleQueryVariables,
  KeeperRuleSetQuery,
  KeeperRuleSetQueryVariables,
  KeeperRuleSetsQuery,
  KeeperRuleSetsQueryVariables,
  KeeperRulesQuery,
  KeeperRulesQueryVariables
} from '@queries/keeper.generated';
import { gql } from '@apollo/client';
import { KEEPER_RULE_FRAGMENT, KEEPER_RULE_SET_FRAGMENT } from '@fragments/keeper';
import { apolloLazyQueryHookGenerator, apolloQueryHookGenerator } from 'utils/hookGenerator';

export const KEEPER_RULE_TRANSACTIONS = gql`
  query KeeperRule($keeperRuleId: ID!) {
    keeperRule(keeperRuleId: $keeperRuleId) {
      id
      transactions
    }
  }
`;

export const KEEPER_RULES = gql`
  ${KEEPER_RULE_FRAGMENT}
  query KeeperRules($keeperRuleSetId: ID!) {
    keeperRules(keeperRuleSetId: $keeperRuleSetId) {
      ...KeeperRuleFields
    }
  }
`;

export const KEEPER_RULE_SETS = gql`
  ${KEEPER_RULE_SET_FRAGMENT}
  query KeeperRuleSets($page: Int, $per: Int) {
    keeperRuleSets(page: $page, per: $per) {
      data {
        ...KeeperRuleSetFields
      }
      currentPage
      per
      totalCount
      totalPages
    }
  }
`;

export const KEEPER_RULE_SET = gql`
  ${KEEPER_RULE_SET_FRAGMENT}
  query KeeperRuleSet($keeperRuleSetId: ID!) {
    keeperRuleSet(keeperRuleSetId: $keeperRuleSetId) {
      ...KeeperRuleSetFields
      availableAccounts
    }
  }
`;

export const KEEPER_RULE_CALCULATE_SCORE = gql`
  query KeeperRuleCalculateScore($keeperRuleId: ID!, $qualifier: String!) {
    keeperRuleCalculateScore(keeperRuleId: $keeperRuleId, qualifier: $qualifier)
  }
`;

export const useKeeperRuleTransactionsQuery = apolloLazyQueryHookGenerator<KeeperRuleQuery, KeeperRuleQueryVariables>(
  KEEPER_RULE_TRANSACTIONS,
  {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useKeeperRulesQuery = apolloLazyQueryHookGenerator<KeeperRulesQuery, KeeperRulesQueryVariables>(
  KEEPER_RULES,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useKeeperRuleSetsQuery = apolloQueryHookGenerator<KeeperRuleSetsQuery, KeeperRuleSetsQueryVariables>(
  KEEPER_RULE_SETS,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useKeeperRuleSetQuery = apolloLazyQueryHookGenerator<KeeperRuleSetQuery, KeeperRuleSetQueryVariables>(
  KEEPER_RULE_SET,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useKeeperRuleCalculateScoreQuery = apolloLazyQueryHookGenerator<
  KeeperRuleCalculateScoreQuery,
  KeeperRuleCalculateScoreQueryVariables
>(KEEPER_RULE_CALCULATE_SCORE, {
  notifyOnNetworkStatusChange: true
});

import { Button, Result } from 'antd';

export function Page500Component() {
  const handleClick = () => {
    window.location.replace('/');
  };

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" onClick={handleClick}>
          Back Home
        </Button>
      }
    />
  );
}

import { useRoutes } from 'react-router-dom';
import { useIsSessionAlive } from 'hooks';
import { routeEndpoints } from 'routes';

function App() {
  const routes = useRoutes(routeEndpoints);
  useIsSessionAlive();

  return <div className="App">{routes}</div>;
}

export default App;

import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

export function useRedirectIfMatches(pathToRedirect: string, pathToMatch: string, replace: boolean = true) {
  const navigate = useNavigate();
  const matchRoute = useMatch({
    path: pathToMatch,
    end: true
  });

  //@TODO maybe replace with <Navigate />
  useEffect(() => {
    if (matchRoute) {
      navigate(pathToRedirect, {
        replace: replace
      });
    }
  }, [navigate, matchRoute, pathToRedirect, replace]);
}

import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { AlertProps } from 'antd/es/alert';
import styles from './AppAlert.module.less';
import cs from 'classnames';

interface IProps {
  className?: string;
  ariaLabel?: string;
  type?: AlertProps['type'] | 'default';
}

export const AppAlert = React.memo(
  ({ className, type, message, showIcon, ariaLabel, ...rest }: IProps & Omit<AlertProps, 'type'>) => {
    const isDefaultType = type === 'default';
    const newType = isDefaultType ? undefined : type;

    const icon =
      type === 'success' ? (
        <CheckCircleOutlined />
      ) : type === 'info' ? (
        <SyncOutlined />
      ) : type === 'warning' ? (
        <InfoCircleOutlined />
      ) : type === 'error' ? (
        <CloseCircleOutlined />
      ) : null;

    return (
      <Alert
        className={cs(styles.noteAlert, {
          [styles.successColor]: type === 'success',
          [styles.infoColor]: type === 'info',
          [styles.warningColor]: type === 'warning',
          [styles.errorColor]: type === 'error',
          [styles.defaultColor]: type === 'default',
          [className!]: !!className
        })}
        message={message}
        type={newType as any}
        showIcon={isDefaultType ? false : showIcon ?? true}
        icon={icon}
        aria-label={ariaLabel}
        {...rest}
      />
    );
  }
);

import React from 'react';
import { Space, Typography } from 'antd';
import styles from './DottedText.module.less';
import cs from 'classnames';
import { InfoType } from 'types';

interface IProps {
  dotSize?: number;
  children?: React.ReactNode;
  type?: InfoType;
  textClassName?: string;
  className?: string;
  ariaLabel?: string;
}

const { Text } = Typography;

export function DottedText({ dotSize = 5, type = 'info', className, textClassName, children, ariaLabel }: IProps) {
  return (
    <Space
      className={cs(styles.dottedText, {
        [className!]: !!className
      })}
    >
      {!!dotSize && (
        <div
          className={cs(styles.dot, {
            [styles.successBg]: type === 'success',
            [styles.infoBg]: type === 'info',
            [styles.warningBg]: type === 'warning',
            [styles.errorBg]: type === 'error'
          })}
          style={{ width: dotSize, height: dotSize }}
        />
      )}
      <Text
        className={cs(styles.dot, textClassName ? textClassName : '', {
          [styles.successColor]: type === 'success',
          [styles.infoColor]: type === 'info',
          [styles.warningColor]: type === 'warning',
          [styles.errorColor]: type === 'error'
        })}
        aria-label={ariaLabel}
      >
        {children}
      </Text>
    </Space>
  );
}

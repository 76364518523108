import React from 'react';
import { Divider } from 'antd';
import { DividerProps } from 'antd/es/divider';
import styles from './AppDivider.module.less';
import cs from 'classnames';

interface IProps {
  topSpace?: string | number;
  bottomSpace?: string | number;
  horizontalSpace?: string | number;
  status?: 'success';
  className?: string;
}

export function AppDivider({
  topSpace,
  bottomSpace,
  horizontalSpace,
  children,
  status,
  className,
  ...rest
}: IProps & DividerProps) {
  let customStyles = {} as any;
  if (topSpace) {
    customStyles.marginTop = topSpace;
  }
  if (bottomSpace) {
    customStyles.marginBottom = bottomSpace;
  }
  if (horizontalSpace) {
    customStyles.marginLeft = horizontalSpace;
    customStyles.marginRight = horizontalSpace;
  }
  return (
    <Divider
      className={cs(styles.lineDivider, {
        [styles.success]: status === 'success',
        [className!]: !!className
      })}
      style={customStyles}
      {...rest}
    >
      {children}
    </Divider>
  );
}

import { gql } from '@apollo/client';

export const BILLPAY_INFORMATION_GENERAL_FRAGMENT = gql`
  fragment BillPayInformationGeneralFragment on BillPayInformation {
    billPayStarts
    billPayTerminated
    feeType
    id
    isConnected
    monthlyFee
    note
    onBehalf
    paymentDayOfWeek
    paymentUser {
      notifications {
        currentPage
        per
        totalCount
        totalPages
        data {
          content
          createdAt
          id
          isDone
          kind
          title
          updatedAt
        }
      }
      id
      name
      email
    }
    sfContactId
    setupDayOfWeek
    setupUser {
      notifications {
        currentPage
        per
        totalCount
        totalPages
        data {
          content
          createdAt
          id
          isDone
          kind
          title
          updatedAt
        }
      }
      id
      name
      email
    }
  }
`;

import { FieldSelectOption } from '../types';
import { camelCase as _camelCase } from 'lodash';

export function pureText(str: string): string {
  const div = document.createElement('div');
  div.innerHTML = str;

  let scripts = div.getElementsByTagName('script');
  let i = scripts.length;
  while (i--) {
    (scripts[i] as any).parentNode.removeChild(scripts[i]);
  }
  return div.textContent || '';
}

export function toUpperCaseFirstChar(str?: string) {
  if (!str) {
    return '';
  }
  let newStr = str.toLowerCase();
  newStr = newStr.replace(newStr.charAt(0), newStr.charAt(0).toUpperCase());
  return newStr;
}

export function getIdentifierForType(typename: string, id: string) {
  return `${typename}:${id}`;
}

export function toCamelCase(str: string): string {
  return _camelCase(str);
}

export function searchStringInArray(options: FieldSelectOption[], value: string): boolean {
  let exists = false;
  for (let item of options) {
    const search = (item.label as string).search(new RegExp(value, 'ig'));
    if (search !== -1) {
      exists = true;
      break;
    }
  }
  return exists;
}

export function convertToReadable(str: string): string {
  return toUpperCaseFirstChar(str.replace(/[_.]/g, ' '));
}

import { RcFile } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { Attachment } from '../apollo/generatedSchema';
import { FileUpload } from 'types';

export const convertToBinaryString = (file: RcFile) => {
  return new Promise((resolve, reject) => {
    var r = new FileReader();
    r.onload = function () {
      resolve(r.result);
    };
    r.onerror = function () {
      reject(undefined);
    };
    r.readAsBinaryString(file);
  });
};

export const convertToBase64Async = (file: RcFile) => {
  return new Promise((resolve, reject) => {
    var r = new FileReader();
    r.onload = function () {
      resolve(r.result as string);
    };
    r.onerror = function () {
      reject(undefined);
    };
    r.readAsDataURL(file);
  });
};

export const convertToBase64Callback = (file: RcFile, callback: (url: string) => unknown) => {
  const r = new FileReader();
  r.addEventListener('load', () => callback(r.result as string));
  r.readAsDataURL(file);
};

export function convertAttachmentsToFileUpload(attachments: Attachment | Attachment[]): FileUpload | undefined {
  try {
    const response = {
      file: {} as UploadFile,
      fileList: [] as UploadFile[]
    };

    if (!Array.isArray(attachments)) {
      attachments = [attachments];
    }

    attachments.forEach(attachment => {
      let file: UploadFile = {
        uid: attachment.id,
        name: attachment.fileName,
        url: attachment.url
      };
      response.fileList.push(file);
    });
    response.file = response.fileList[0];
    return response;
  } catch (e) {
    //
  }
  return undefined;
}

import { PickerProps } from 'antd/es/date-picker/generatePicker';
import { Dayjs } from 'dayjs';

export enum AppDateFormats {
  Year = 'YYYY',
  AppStandardDate = 'MM/DD/YYYY',
  AppCustomDate = 'MMMM DD, YYYY',
  AppStandardDateMonth = 'MM/YYYY',
  AppStandardDateTime = 'MM/DD/YYYY HH:mm',
  AppStandardDateTimeMonthYear = 'MMMM YYYY',
  AppStandardDateTime_lll = 'D MMM YYYY, hh:mm A',
  ISO8601Date = 'YYYY-MM-DD',
  ISO8601DateTime = 'YYYY-MM-DDTHH:mm:ssZ[Z]',
  Month = 'MMMM',
  Day = 'DD',
  MonthDay = 'MM/DD'
}

export type DayjsDatePickerProps = PickerProps<Dayjs>;

import { UploadFile } from 'antd/es/upload/interface';

export enum ImageTypes {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png'
}

export enum ExcelTypes {
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const PdfType = 'application/pdf';

export type FileUpload = {
  file: UploadFile;
  fileList: UploadFile[];
};

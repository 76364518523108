import { Pages, Permissions } from '../types';
import { parseJSON } from '../utils/localStorageUtils';
import { IUserAbilityResponse } from 'interfaces';

export function useUserAbility(key: Pages): IUserAbilityResponse {
  let roles: any = parseJSON(localStorage.getItem(process.env.REACT_APP_ROLE_KEY as string));
  const rolesByKey = (roles?.[key] ?? []) as string[];
  const map = new Map();
  rolesByKey.forEach(item => map.set(item, !!item));

  const can = (action: Permissions): boolean => {
    return map.has(action);
  };

  return {
    canRead: can(Permissions.READ),
    canCreate: can(Permissions.CREATE),
    canUpdate: can(Permissions.UPDATE),
    canDelete: can(Permissions.DELETE)
  };
}

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { AuthComponentWrapper, RoutePermissions, RouteWrapper } from 'components';
import { appRoutes } from './routes';
import { IAppRoutes } from 'interfaces';

export const routeEndpoints = getRouteEndpoints(appRoutes);
export const linearRouteData = getLinearRoutes(appRoutes);

export function getRouteEndpoints(appRoutes: IAppRoutes[]): RouteObject[] {
  return appRoutes.map(item => {
    const RComponent = item.component;
    const RLazyComponent = item.lazyComponent ? item.lazyComponent : null;
    const RExtraComponent = item.extraComponent ? item.extraComponent : null;

    const mainElement = item.ignoreAuth ? (
      <RComponent />
    ) : (
      <AuthComponentWrapper>
        <RComponent />
      </AuthComponentWrapper>
    );

    const element: React.ReactElement = RExtraComponent ? (
      <RExtraComponent>{mainElement}</RExtraComponent>
    ) : (
      mainElement
    );
    const wrappedElement = (
      <RouteWrapper component={RLazyComponent ? RLazyComponent : null}>
        {/*@TODO remote conditional rendering when all routes will have permission */}
        {!!item?.permission ? (
          <RoutePermissions permission={item?.permission} authorization={item?.authorization}>
            {element}
          </RoutePermissions>
        ) : (
          element
        )}
      </RouteWrapper>
    );

    return {
      ...(item.path && { path: item.path }),
      ...(item.index && { index: item.index }),
      element: wrappedElement,
      ...((item.children || []).length && { children: getRouteEndpoints(item.children!) })
    } as RouteObject;
  });
}

interface IGetLinearRoutesData {
  [key: string]: {
    title: string;
    isDisabled?: boolean;
  };
}
export function getLinearRoutes(routesList: IAppRoutes[]): IGetLinearRoutesData {
  let data: IGetLinearRoutesData = {};
  function getDataRec(item: IAppRoutes, prefix?: string) {
    const newPrefix = (prefix ?? '') + (item?.path ?? '') + (!!prefix && !!item?.path ? '/' : '');
    if (item.title && item.path && !item.index) {
      data[newPrefix.replace(/\/$/, '')] = {
        title: item.title,
        isDisabled: !!item.breadcrumbDisabled
      };
    }
    if ((item?.children || []).length > 0) {
      item.children!.forEach((child: IAppRoutes) => {
        getDataRec(child, newPrefix);
      });
    }
  }
  routesList.forEach(item => {
    getDataRec(item);
  });
  return data;
}

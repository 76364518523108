import React from 'react';
import { AppDivider, Attachments } from 'components';
import { Answer, Attachment, History, QuestionComment } from 'apollo/generatedSchema';
import styles from './QuestionHistory.module.less';
import HistoryActionComponent from './HistoryActionComponent';
import HistoryComment from './HistoryComment';
import { AppDateFormats, QuestionHistoryType } from 'types';
import { dateTimeUtils } from 'utils/dateTimeUtils';

interface IProps {
  history?: QuestionHistoryType[];
  content?: string;
  completed?: boolean;
  attachments?: Attachment[];
}

export const QuestionHistory = React.memo(({ history, content, completed, attachments }: IProps) => {
  const getHistoryItem = (item: QuestionHistoryType) => {
    switch (item?.__typename) {
      case 'History': {
        const typedItem = item as History;
        return (
          <HistoryActionComponent
            author={typedItem?.user?.name || ''}
            action={typedItem?.action}
            actionDate={
              dateTimeUtils.toPacificTimezoneString(typedItem?.updatedAt, AppDateFormats.AppStandardDateMonth) as string
            }
          />
        );
      }
      case 'Answer': {
        const typedItem = item as Answer;
        return <HistoryComment answer={typedItem} />;
      }
      case 'QuestionComment': {
        const typedItem = item as QuestionComment;
        return <HistoryComment comment={typedItem} />;
      }
      default:
        return null;
    }
  };

  return (
    <div className={styles.history}>
      <div className={styles.historyList}>
        <div dangerouslySetInnerHTML={{ __html: content ?? '' }}></div>
        {!!attachments?.length && <Attachments attachments={attachments} />}
        {history?.map((item: any, index: number) => {
          return <React.Fragment key={index}>{getHistoryItem(item)}</React.Fragment>;
        })}
        {completed && (
          <AppDivider
            orientation="center"
            topSpace={5}
            bottomSpace={5}
            status="success"
            className={styles.historyEndDivider}
          >
            Question has been successfully completed
          </AppDivider>
        )}
      </div>
    </div>
  );
});

import React from 'react';
import { Col, Row, Typography } from 'antd';
import { gutter16 } from 'theme';
import styles from './HalvedGridDataContainer.module.less';

interface IProps {
  label: React.ReactNode;
  value: React.ReactNode;
  ariaLabel?: string;
}

export const HalvedGridContainer: React.FC<IProps> = React.memo(({ label, value, ariaLabel }) => {
  return (
    <Row gutter={[gutter16, gutter16]} justify="space-between">
      <Col span={12}>
        <Typography.Text className={styles.label}>{label}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text className={styles.value} aria-label={ariaLabel}>
          {value}
        </Typography.Text>
      </Col>
    </Row>
  );
});

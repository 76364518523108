import React, { ForwardedRef } from 'react';
import { Row, RowProps } from 'antd';
import { gutter16, gutterVertical } from 'theme';

interface IProps extends RowProps {
  children: React.ReactNode | React.ReactNode[];
}

export const AppPage = React.memo(
  React.forwardRef((props: IProps, ref: ForwardedRef<any>) => {
    const { children, ...rest } = props;
    return (
      <Row gutter={[gutter16, gutterVertical]} {...rest} ref={ref}>
        {children}
      </Row>
    );
  })
);

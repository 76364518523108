import { useEffect, useMemo, useRef, useState } from 'react';
import { useClientsSearchLazyQuery } from '@queries/client';
import { FieldSelectOption } from '../types';
import { debounce as _debounce } from 'lodash';

export function useDebounceSearchClient(qboConnected?: boolean, defaultOptionsList?: FieldSelectOption[]) {
  const abortC = useRef<AbortController>();

  const [getClients, { data, loading }] = useClientsSearchLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [options, setOptions] = useState<any>([]);
  useEffect(() => {
    if ((defaultOptionsList || []).length) {
      setOptions(defaultOptionsList ?? []);
    }
  }, [defaultOptionsList]);

  const onDebounceSearch = useMemo(() => {
    return _debounce((value: string) => {
      if (value) {
        setOptions([]);
        if (abortC.current instanceof AbortController) {
          abortC.current.abort();
        }
        abortC.current = new AbortController();
        getClients({
          variables: {
            search: value,
            ...(qboConnected !== undefined ? { qboConnected } : {})
          },
          context: {
            fetchOptions: {
              signal: abortC.current.signal
            }
          }
        });
      }
    }, 800);
  }, [getClients, qboConnected]);

  useEffect(() => {
    const clientEdges = data?.clientsSearch || [];
    if (clientEdges.length) {
      setOptions(
        clientEdges.map(clientEdge => {
          return {
            value: clientEdge?.id,
            label: clientEdge?.name,
            contactNames: clientEdge.salesforceContacts.map(contact => `${contact.firstname} ${contact.lastname}`)
          };
        })
      );
    }
  }, [data]);

  return {
    options,
    loading,
    onDebounceSearch
  };
}

import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import styles from './styles/DefautBordered.module.less';
import cs from 'classnames';

interface IProps extends Omit<ButtonProps, 'type'> {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export function PrimaryGhostButton({ className, children, ...buttonProps }: IProps) {
  return (
    <Button
      type="primary"
      ghost
      className={cs(styles.defaultBorderedButton, { [className!]: !!className })}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

import {
  ClientBillPayInformationQuery,
  ClientBillPayInformationQueryVariables,
  ClientCardQuery,
  ClientCardQueryVariables,
  ClientCardsQuery,
  ClientCardsQueryVariables,
  ClientEditDataQuery,
  ClientEditDataQueryVariables,
  ClientExternalConnectionsQuery,
  ClientExternalConnectionsQueryVariables,
  ClientForMenuQuery,
  ClientForMenuQueryVariables,
  ClientManagerAndTaxContactQuery,
  ClientManagerAndTaxContactQueryVariables,
  ClientQboStatusesQuery,
  ClientQboStatusesQueryVariables,
  ClientSalesforceContactOptionsQuery,
  ClientSalesforceContactOptionsQueryVariables,
  ClientSalesforceContactsQuery,
  ClientSalesforceContactsQueryVariables,
  ClientShortcutsQuery,
  ClientShortcutsQueryVariables,
  ClientsInformationQuery,
  ClientsInformationQueryVariables,
  ClientsSearchQuery,
  ClientsSearchQueryVariables
} from '@queries/client.generated';
import { gql } from '@apollo/client';
import { BILLPAY_INFORMATION_GENERAL_FRAGMENT } from '@fragments/billPayInformation';
import { CLIENT_CARD_FRAGMENT, CLIENT_EDIT_FRAGMENT } from '@fragments/client';
import { SALESFORCE_CONTACT_FRAGMENT } from '@fragments/salesforceContact';
import { SHORTCUT_FRAGMENT } from '@fragments/shortcut';
import { ALL_USER_FIELDS_FRAGMENT } from '@fragments/user';
import { apolloLazyQueryHookGenerator, apolloQueryHookGenerator } from 'utils/hookGenerator';

export const CLIENT_CARDS = gql`
  ${CLIENT_CARD_FRAGMENT}
  query ClientCards($filter: ClientsFilter, $page: Int, $per: Int) {
    clients(filter: $filter, page: $page, per: $per) {
      data {
        ...ClientCard
      }
      currentPage
      per
      totalCount
      totalPages
    }
  }
`;

export const CLIENT_SEARCH = gql`
  query ClientsSearch($qboConnected: Boolean, $search: String!) {
    clientsSearch(qboConnected: $qboConnected, search: $search) {
      id
      salesforceContacts {
        firstname
        lastname
      }
      name
    }
  }
`;

export const useClientsSearchLazyQuery = apolloLazyQueryHookGenerator<ClientsSearchQuery, ClientsSearchQueryVariables>(
  CLIENT_SEARCH,
  {
    fetchPolicy: 'network-only'
  }
);

export const useClientCardsQuery = apolloQueryHookGenerator<ClientCardsQuery, ClientCardsQueryVariables>(CLIENT_CARDS, {
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_CARD = gql`
  ${CLIENT_CARD_FRAGMENT}
  query ClientCard($id: ID!) {
    client(id: $id) {
      ...ClientCard
    }
  }
`;
export const useClientCardQuery = apolloQueryHookGenerator<ClientCardQuery, ClientCardQueryVariables>(CLIENT_CARD, {
  notifyOnNetworkStatusChange: true
});

export const CLIENT_FOR_MENU = gql`
  query ClientForMenu($id: ID!) {
    client(id: $id) {
      id
      salesforceAccount {
        id
      }
      qboStatus
    }
  }
`;
export const useClientForMenuQuery = apolloLazyQueryHookGenerator<ClientForMenuQuery, ClientForMenuQueryVariables>(
  CLIENT_FOR_MENU,
  {
    notifyOnNetworkStatusChange: true
  }
);

export const CLIENT_MANAGER_TAX_CONTACT = gql`
  ${ALL_USER_FIELDS_FRAGMENT}
  query ClientManagerAndTaxContact($id: ID!) {
    client(id: $id) {
      id
      rAndDManager {
        ...AllUserFields
      }
      taxContact {
        ...AllUserFields
      }
      manager {
        ...AllUserFields
      }
      seniorManager {
        ...AllUserFields
      }
    }
  }
`;
export const useClientManagerAndTaxContactQuery = apolloQueryHookGenerator<
  ClientManagerAndTaxContactQuery,
  ClientManagerAndTaxContactQueryVariables
>(CLIENT_MANAGER_TAX_CONTACT, {
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_EXTERNAL_CONNECTIONS = gql`
  query ClientExternalConnections($id: ID!) {
    client(id: $id) {
      id
      externalConnections {
        karbon
        qboErrors
        salesforceErrors
        qbo
        salesforce
        stripe
        salesforceClientStatus
      }
    }
  }
`;
export const useClientExternalConnectionsQuery = apolloQueryHookGenerator<
  ClientExternalConnectionsQuery,
  ClientExternalConnectionsQueryVariables
>(CLIENT_EXTERNAL_CONNECTIONS, {
  fetchPolicy: 'cache-and-network',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_BILLPAY_INFORMATION = gql`
  ${BILLPAY_INFORMATION_GENERAL_FRAGMENT}
  query ClientBillPayInformation($id: ID!) {
    client(id: $id) {
      id
      billPayInformation {
        ...BillPayInformationGeneralFragment
      }
    }
  }
`;

export const useClientBillPayInformationQuery = apolloQueryHookGenerator<
  ClientBillPayInformationQuery,
  ClientBillPayInformationQueryVariables
>(CLIENT_BILLPAY_INFORMATION, {
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_SALESFORCE_CONTACTS_OPTIONS = gql`
  query ClientSalesforceContactOptions($clientId: ID!) {
    client(id: $clientId) {
      id
      salesforceContacts {
        id
        firstname
        lastname
        email
      }
    }
  }
`;

export const useClientSalesforceContactOptionsLazyQuery = apolloLazyQueryHookGenerator<
  ClientSalesforceContactOptionsQuery,
  ClientSalesforceContactOptionsQueryVariables
>(CLIENT_SALESFORCE_CONTACTS_OPTIONS, {
  fetchPolicy: 'cache-and-network',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_SALESFORCE_CONTACTS = gql`
  ${SALESFORCE_CONTACT_FRAGMENT}
  query ClientSalesforceContacts($clientId: ID!) {
    client(id: $clientId) {
      id
      salesforceContacts {
        ...AllSalesforceContactFields
      }
    }
  }
`;

export const useClientSalesforceContactsQuery = apolloQueryHookGenerator<
  ClientSalesforceContactsQuery,
  ClientSalesforceContactsQueryVariables
>(CLIENT_SALESFORCE_CONTACTS, {
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_EDIT_DATA = gql`
  ${CLIENT_EDIT_FRAGMENT}
  query ClientEditData($clientId: ID!) {
    client(id: $clientId) {
      ...ClientEdit
    }
  }
`;

export const useClientEditDataLazyQuery = apolloLazyQueryHookGenerator<
  ClientEditDataQuery,
  ClientEditDataQueryVariables
>(CLIENT_EDIT_DATA, {
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-only',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_QBO_STATUSES = gql`
  query ClientQboStatuses($id: ID!) {
    clientQboStatuses(id: $id) {
      apAging
      apAgingError
      arAging
      arAgingError
      balanceSheet
      balanceSheetError
      cashFlow
      cashFlowError
      clientPreferences
      clientPreferencesError
      createdAt
      generalLedger
      generalLedgerError
      id
      invalidKeys
      profitAndLoss
      profitAndLossError
      transactionList
      transactionListError
      updatedAt
      vendorExpenses
      vendorExpensesError
    }
  }
`;

export const useClientQboStatusesQuery = apolloQueryHookGenerator<
  ClientQboStatusesQuery,
  ClientQboStatusesQueryVariables
>(CLIENT_QBO_STATUSES, {
  fetchPolicy: 'cache-and-network',
  notifyOnNetworkStatusChange: true
});

export const CLIENT_SHORTCUTS = gql`
  ${SHORTCUT_FRAGMENT}
  query ClientShortcuts($id: ID!) {
    client(id: $id) {
      id
      shortcuts {
        ...ShortcutFields
      }
    }
  }
`;

export const useClientShortcutsQuery = apolloQueryHookGenerator<ClientShortcutsQuery, ClientShortcutsQueryVariables>(
  CLIENT_SHORTCUTS,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const CLIENTS_INFORMATION = gql`
  query ClientsInformation {
    clientsInformation {
      billPayNotSetCount
      mcpBudgetNotSetCount
      myClientsCount
      qboOutdatedCount
    }
  }
`;

export const useClientsInformation = apolloQueryHookGenerator<
  ClientsInformationQuery,
  ClientsInformationQueryVariables
>(CLIENTS_INFORMATION, {
  fetchPolicy: 'cache-and-network',
  notifyOnNetworkStatusChange: true
});

import { Spin } from 'antd';
import { SpinProps } from 'antd/es/spin';
import styles from './styles/Loading.module.less';

export function AppSpin(props?: SpinProps) {
  return (
    <div className={styles.contentCenter}>
      <Spin {...props} />
    </div>
  );
}

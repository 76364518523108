import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { ANSWER_FRAGMENT, COMMENT_FRAGMENT, HISTORY_FRAGMENT } from '@fragments/questions';

export const QUESTION_FRAGMENT = gql`
  ${HISTORY_FRAGMENT}
  ${ANSWER_FRAGMENT}
  ${COMMENT_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  fragment QuestionFields on Question {
    answers {
      ...AnswerFields
    }
    attachments {
      ...AttachmentFields
    }
    closePeriod
    comments {
      ...CommentFields
    }
    completedAt
    content
    createdAt
    history {
      ...HistoryFields
    }
    id
    movedToClientAt
    seen
    showFastAnswers
    status
    topic
    updatedAt
    user {
      id
      email
    }
  }
`;

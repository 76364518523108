import React from 'react';
import { AppSkeleton } from 'components';
import { IAppRoutes } from 'interfaces';
import ClientAnalysis from 'pages/clientAnalysis/ClientAnalysis';
import ClientPortal from 'pages/clientPages/clientPortal/ClientPortal';
import Reports from 'pages/clientPages/reports/Reports';
import ClientPortalGeneral from 'pages/clientPortalGeneral/ClientPortalGeneral';
import ConnectToQbo from 'pages/clients/ConnectToQbo';
import Page403 from 'pages/page403/Page403';
import Page404 from 'pages/page404/Page404';
import Page500 from 'pages/page500/Page500';
import { Pages } from 'types';

//Main Menu Components
const Login = React.lazy(() => import('pages/login/Login'));
const Register = React.lazy(() => import('pages/register/Register'));
const Home = React.lazy(() => import('pages/home/Home'));
const Clients = React.lazy(() => import('pages/clients/Clients'));
const VendorReports = React.lazy(() => import('pages/vendor/VendorReports'));
const CreateVendorRequest = React.lazy(() => import('pages/vendor/CreateVendorRequest'));
const VendorAccountGroups = React.lazy(() => import('pages/vendor/VendorAccountGroups'));
const KruzeKeeper = React.lazy(() => import('pages/kruzeKeeper/KruzeKeeper'));
const KruzeKeeperItem = React.lazy(() => import('pages/kruzeKeeper/KruzeKeeperItem'));
const Users = React.lazy(() => import('pages/users/Users'));
const UserRoles = React.lazy(() => import('pages/users/UserRoles'));
const ClientsReport = React.lazy(() => import('pages/clientsReport/ClientsReport'));
const ClientReportUpsert = React.lazy(() => import('pages/clientsReport/ClientReportUpsert'));
const ClientRevenue = React.lazy(() => import('pages/clientAnalysis/ClientRevenue'));
const ClientRevenueItem = React.lazy(() => import('pages/clientAnalysis/ClientRevenueItem'));
const VendorUsage = React.lazy(() => import('pages/clientAnalysis/VendorUsage'));
const QuestionsStatistics = React.lazy(() => import('pages/questionsStatistics/QuestionsStatistics'));
const DocsAndLinks = React.lazy(() => import('pages/docsAndLinks/DocsAndLinks'));
const Notifications = React.lazy(() => import('pages/clientPages/notifications/Notifications'));
/*
import Other from 'pages/other/Other';
const Plaid = React.lazy(() => import('pages/plaid/Plaid'));
const OutdatedBankConnections = React.lazy(() => import('pages/plaid/OutdatedBankConnections'));
const FailedReports = React.lazy(() => import('pages/plaid/FailedReports'));
const ReconciliationSettings = React.lazy(() => import('pages/plaid/ReconciliationSettings'));
*/
const QaItems = React.lazy(() => import('pages/clientPortalGeneral/QaItems'));
const KruzeTips = React.lazy(() => import('pages/clientPortalGeneral/KruzeTips'));
const Rewards = React.lazy(() => import('pages/clientPortalGeneral/Rewards'));
const RewardItem = React.lazy(() => import('pages/clientPortalGeneral/RewardItem'));

const Other = React.lazy(() => import('pages/other/Other'));
const RdReport = React.lazy(() => import('pages/other/RdReport'));
const VersionLogs = React.lazy(() => import('pages/other/VersionLogs'));
const EmailLists = React.lazy(() => import('pages/other/EmailLists'));
/*
const AllSalesforceChanges = React.lazy(() => import('pages/other/AllSalesforceChanges'));
const ImportReviewers = React.lazy(() => import('pages/other/ImportReviewers'));

const TeamCards = React.lazy(() => import('pages/other/TeamCards'));
const Notifications = React.lazy(() => import('pages/notifications/Notifications'));
*/

//Client Menu Components
const Client = React.lazy(() => import('pages/clientPages/client/Client'));
const Info = React.lazy(() => import('pages/clientPages/info/Info'));
const General = React.lazy(() => import('pages/clientPages/info/General'));
const BillPay = React.lazy(() => import('pages/clientPages/info/BillPay'));
const McpInfo = React.lazy(() => import('pages/clientPages/info/McpInfo'));
const TaxInfo = React.lazy(() => import('pages/clientPages/info/TaxInfo'));
const VcFirm = React.lazy(() => import('pages/clientPages/info/VcFirms'));
const SpecificProcedures = React.lazy(() => import('pages/clientPages/specificProcedures/SpecificProcedures'));
const SaltInfoGeneral = React.lazy(() => import('pages/clientPages/salt/SaltInfoGeneral'));
const SalesTaxInfo = React.lazy(() => import('pages/clientPages/salesTaxInfo/SalesTaxInfo'));
const Contacts = React.lazy(() => import('pages/clientPages/contacts/Contacts'));
const FinancialPackets = React.lazy(() => import('pages/clientPages/reports/FinancialPackets'));
const Vendor1099 = React.lazy(() => import('pages/clientPages/reports/Vendor1099'));
const Vendor1099Excel = React.lazy(() => import('pages/clientPages/reports/Vendor1099Excel'));
const VarianceAnalysis = React.lazy(() => import('pages/clientPages/reports/VarianceAnalysis'));
const AccountAnalysis = React.lazy(() => import('pages/clientPages/reports/AccountAnalysis'));
const TaxPapers = React.lazy(() => import('pages/clientPages/reports/TaxPapers'));
const Charts = React.lazy(() => import('pages/clientPages/charts/Charts'));
const Chart = React.lazy(() => import('pages/clientPages/charts/Chart'));
const ChartUpsert = React.lazy(() => import('pages/clientPages/charts/ChartUpsert'));
const FeeIncreaseReports = React.lazy(() => import('pages/clientPages/reports/FeeIncreaseReports'));
const ManagersHistory = React.lazy(() => import('pages/clients/ManagersHistory'));
const TaxDashboard = React.lazy(() => import('pages/clientPages/taxDashboard/TaxDashboard'));

/*const SalesTaxInfo = React.lazy(() => import('pages/clientPages/salesTaxInfo/SalesTaxInfo'));
const PlaidReports = React.lazy(() => import('pages/clientPages/reports/PlaidReports'));
const Mda = React.lazy(() => import('pages/clientPages/mda/Mda'));
const NotesAndQuestions = React.lazy(() => import('pages/clientPages/notesAndQuestions/NotesAndQuestions'));
const NotesAndQuestionsItem = React.lazy(() => import('pages/clientPages/notesAndQuestions/NotesAndQuestionsItem'));
const RdTaxCredit = React.lazy(() => import('pages/clientPages/rdTaxCredit/RdTaxCredit'));
const RdVendorTab = React.lazy(() => import('pages/clientPages/rdTaxCredit/RdVendorTab'));
const RdDynamicTab = React.lazy(() => import('pages/clientPages/rdTaxCredit/RdDynamicTab'));
const RdPayrollTab = React.lazy(() => import('pages/clientPages/rdTaxCredit/RdPayrollTab'));
const RdSummaryTab = React.lazy(() => import('pages/clientPages/rdTaxCredit/RdSummaryTab'));
const RdAddTab = React.lazy(() => import('pages/clientPages/rdTaxCredit/RdAddTab'));
*/
const ClientPortalUsers = React.lazy(() => import('pages/clientPages/clientPortal/Users'));
const SalesforceChanges = React.lazy(() => import('pages/clientPages/salesforceChanges/SalesforceChanges'));
const AccountSalesforceChanges = React.lazy(
  () => import('pages/clientPages/salesforceChanges/AccountSalesforceChanges')
);
const ContactSalesforceChanges = React.lazy(
  () => import('pages/clientPages/salesforceChanges/ContactSalesforceChanges')
);
const Questions = React.lazy(() => import('pages/clientPages/clientPortal/Questions'));
const QuestionItem = React.lazy(() => import('pages/clientPages/clientPortal/QuestionItem'));
const ClientPortalFinancialBundles = React.lazy(() => import('pages/clientPages/clientPortal/FinancialBundles'));

export const appRoutes: IAppRoutes[] = [
  {
    ignoreAuth: true,
    path: 'login',
    title: 'Login',
    component: Login
  },
  {
    ignoreAuth: true,
    path: 'register',
    title: 'Register',
    component: Register
  },
  {
    path: 'connect_qbo',
    component: ConnectToQbo
  },
  {
    path: '/',
    title: 'Home',
    component: Home,
    children: [
      {
        index: true,
        title: 'Clients',
        component: Clients
      },
      {
        path: 'clients',
        title: 'Clients',
        component: Clients
      },
      {
        path: 'vendor/reports',
        title: 'Vendor Reports',
        component: VendorReports,
        permission: Pages.vendor_reporting
      },
      {
        path: 'vendor/reports/create_request',
        title: 'Create Vendor Request',
        component: CreateVendorRequest,
        permission: Pages.vendor_reporting,
        authorization: ['canCreate']
      },
      {
        path: 'vendor/reports/create_request/groups',
        title: 'Show Groups',
        component: VendorAccountGroups,
        permission: Pages.vendor_reporting,
        authorization: ['canCreate']
      },
      {
        path: 'kruze_keeper',
        title: 'Kruze Keeper',
        component: KruzeKeeper,
        permission: Pages.kruze_keeper
      },
      {
        path: 'client_analysis',
        title: 'Client Analysis',
        breadcrumbDisabled: true,
        component: ClientAnalysis,
        children: [
          {
            index: true,
            component: ClientRevenue,
            permission: Pages.client_analysis
          },
          {
            path: 'revenue',
            title: 'Client Revenue',
            component: ClientRevenue,
            permission: Pages.client_analysis
          },
          {
            path: 'revenue/:revenueId',
            component: ClientRevenueItem,
            permission: Pages.client_analysis
          },
          {
            path: 'vendor_usage',
            title: 'Vendor Usage',
            component: VendorUsage,
            permission: Pages.client_analysis
          }
        ]
      },
      {
        path: 'kruze_keeper/:kruzeKeeperId',
        title: 'Kruze Keeper Item',
        component: KruzeKeeperItem,
        permission: Pages.kruze_keeper,
        authorization: ['canDelete']
      },
      {
        path: 'clients_report',
        title: 'Clients Report',
        component: ClientsReport,
        permission: Pages.client_report
      },
      {
        path: 'clients_report/create',
        title: 'New Report',
        component: ClientReportUpsert,
        permission: Pages.client_report,
        authorization: ['canCreate']
      },
      {
        path: 'clients_report/update/:clientReportId',
        title: 'New Report',
        component: ClientReportUpsert,
        permission: Pages.client_report,
        authorization: ['canUpdate']
      },
      {
        path: 'users',
        title: 'Users',
        component: Users,
        permission: Pages.user_administration
      },
      {
        path: 'user/roles/:userId',
        component: UserRoles,
        permission: Pages.user_administration
      },
      {
        path: 'questions_statistics',
        title: 'Questions Statistics',
        component: QuestionsStatistics,
        permission: Pages.client_analysis
      },

      {
        path: 'notifications',
        title: 'Notifications',
        component: Notifications,
        permission: Pages.tax_information
      },
      /*{
        path: 'plaid',
        title: 'Plaid',
        breadcrumbDisabled: true,
        component: Plaid,
        children: [
          {
            index: true,
            component: OutdatedBankConnections
          },
          {
            path: 'outdated_bank_connections',
            title: 'Outdated Bank Connections',
            component: OutdatedBankConnections
          },
          {
            path: 'failed_reports',
            title: 'Failed Reports',
            component: FailedReports
          },
          {
            path: 'reconciliation_settings',
            title: 'Reconciliation Settings',
            component: ReconciliationSettings
          }
        ]
      },*/
      {
        path: 'docs_and_links',
        title: 'Docs And Links',
        component: DocsAndLinks,
        permission: Pages.site_administration
      },
      {
        path: 'client_portal',
        title: 'Client Portal',
        breadcrumbDisabled: true,
        component: ClientPortalGeneral,
        children: [
          {
            index: true,
            component: KruzeTips,
            permission: Pages.client_portal
          },
          {
            path: 'kruze_tips',
            title: 'Kruze Tips',
            component: KruzeTips,
            permission: Pages.client_portal
          },
          {
            path: 'qa_items',
            title: 'Q&A Items',
            component: QaItems,
            permission: Pages.client_portal
          },
          {
            path: 'rewards',
            title: 'Rewards',
            component: Rewards,
            permission: Pages.client_portal
          },
          {
            path: 'rewards/:rewardId',
            component: RewardItem,
            permission: Pages.client_portal
          }
        ]
      },
      {
        path: 'other',
        title: 'Other',
        breadcrumbDisabled: true,
        component: Other,
        children: [
          /*{
            index: true,
            component: AllSalesforceChanges,
            permission: Pages.salesforce_approval
          },
          {
            path: 'all_salesforce_changes',
            title: 'All Salesforce Changes',
            component: AllSalesforceChanges,
            permission: Pages.salesforce_approval
          },*/
          {
            path: 'rd_report',
            title: 'R&D report',
            component: RdReport,
            permission: Pages.r_and_d
          },
          /*{
            path: 'import_reviewers',
            title: 'Import Reviewers',
            component: ImportReviewers,
            permission: Pages.site_administration
          }*/
          {
            path: 'version_logs',
            title: 'Version Logs',
            component: VersionLogs,
            permission: Pages.site_administration
          },
          {
            path: 'email_lists',
            title: 'Email lists',
            component: EmailLists,
            permission: Pages.site_administration
          }
          /*{
            path: 'team_cards',
            title: 'Team Cards',
            component: TeamCards,
            permission: Pages.client_portal
          }*/
        ]
      },
      /*{
        path: 'notifications',
        title: 'Notifications',
        component: Notifications
      },*/
      {
        path: 'clients/:clientId',
        component: Client,
        title: 'Client',
        children: [
          {
            component: Info,
            children: [
              {
                index: true,
                component: General,
                lazyComponent: AppSkeleton,
                permission: Pages.account_details
              },
              {
                path: 'general',
                component: General,
                lazyComponent: AppSkeleton,
                permission: Pages.account_details
              },
              {
                path: 'billpay',
                component: BillPay,
                lazyComponent: AppSkeleton,
                permission: Pages.account_details
              },
              {
                path: 'mcp_info',
                component: McpInfo,
                lazyComponent: AppSkeleton,
                permission: Pages.budget_and_fees
              },
              {
                path: 'tax_info',
                component: TaxInfo,
                lazyComponent: AppSkeleton,
                permission: Pages.tax_information
              },
              {
                path: 'vc_firm',
                component: VcFirm,
                lazyComponent: AppSkeleton,
                permission: Pages.account_details
              }
            ]
          },
          {
            path: 'contacts',
            title: 'Contacts',
            component: Contacts,
            permission: Pages.account_details
          },
          {
            path: 'specific_procedures',
            title: 'Specific Procedures',
            component: SpecificProcedures,
            permission: Pages.account_details
          },
          {
            path: 'account_manager_history',
            title: 'Account Manager History',
            component: ManagersHistory,
            permission: Pages.account_details
          },
          {
            path: 'tax_manager_history',
            title: 'Account Manager History',
            component: ManagersHistory,
            permission: Pages.account_details
          },
          {
            path: 'r_and_d_manager_history',
            title: 'R&D Manager History',
            component: ManagersHistory,
            permission: Pages.account_details
          },
          {
            path: 'salt',
            title: 'SALT Info',
            component: SaltInfoGeneral,
            permission: Pages.tax_information
          },
          {
            path: 'sales_tax_info',
            title: 'Sales Tax Info',
            component: SalesTaxInfo,
            permission: Pages.tax_information
          },
          {
            path: 'tax_dashboard',
            title: 'Karbon Data',
            component: TaxDashboard,
            permission: Pages.tax_information
          },
          {
            path: 'reports',
            title: 'Reports',
            breadcrumbDisabled: true,
            component: Reports,
            children: [
              {
                index: true,
                component: FinancialPackets,
                permission: Pages.financial_packets
              },
              {
                path: 'financial_packets',
                title: 'Financial Packets',
                component: FinancialPackets,
                permission: Pages.financial_packets
              },
              {
                path: 'vendor1099',
                title: 'Vendor1099',
                component: Vendor1099,
                permission: Pages.administration_1099
              },
              {
                path: 'vendor1099_excel',
                title: 'Vendor1099Excel',
                component: Vendor1099Excel,
                permission: Pages.administration_1099
              },
              {
                path: 'account_analysis',
                title: 'Account Analysis',
                component: AccountAnalysis,
                permission: Pages.reporting
              },
              {
                path: 'variance_analysis',
                title: 'Variance Analysis',
                component: VarianceAnalysis,
                permission: Pages.reporting
              },
              {
                path: 'tax_papers',
                title: 'Tax Papers',
                component: TaxPapers,
                permission: Pages.tax_information
              },
              /*{
                path: 'plaid_reports',
                title: 'Plaid Reports',
                component: PlaidReports
              }*/
              {
                path: 'fee_increase_reports',
                title: 'Fee increase reports',
                component: FeeIncreaseReports,
                permission: Pages.fee_increase_report
              }
            ]
          },
          {
            path: 'charts/create',
            title: 'New Chart',
            component: ChartUpsert,
            permission: Pages.reporting
          },
          {
            path: 'charts/:chartId/edit',
            title: 'Edit Chart',
            component: ChartUpsert,
            permission: Pages.reporting
          },
          {
            path: 'charts',
            title: 'Charts',
            component: Charts,
            permission: Pages.reporting
          },
          {
            path: 'charts/:chartId',
            title: 'Chart',
            component: Chart,
            permission: Pages.reporting
          },
          /*{
            path: 'md_a',
            title: 'MD&A',
            component: Mda
          },*/
          /*{
            path: 'notes_questions',
            title: 'Notes & Questions',
            component: NotesAndQuestions
          },
          {
            path: 'notes_questions/:noteQuestionId',
            component: NotesAndQuestionsItem
          },*/
          /*{
            path: 'rd_tax_credit',
            title: 'R&D Tax Credit',
            component: RdTaxCredit,
            permission: Pages.r_and_d,
            children: [
              {
                index: true,
                component: RdPayrollTab
              },
              {
                path: 'payroll',
                component: RdPayrollTab
              },
              {
                path: 'vendors_total',
                component: RdVendorTab
              },
              {
                path: 'summary',
                component: RdSummaryTab
              },
              {
                path: 'add',
                component: RdAddTab
              },
              {
                path: ':rdId',
                component: RdDynamicTab
              }
            ]
          },*/
          {
            path: 'client_portal',
            title: 'Client Portal',
            breadcrumbDisabled: true,
            component: ClientPortal,
            children: [
              {
                index: true,
                component: Questions,
                permission: Pages.notes_and_questions
              },
              {
                path: 'questions',
                title: 'Questions',
                component: Questions,
                permission: Pages.notes_and_questions
              },
              {
                path: 'questions/:questionId',
                component: QuestionItem,
                permission: Pages.notes_and_questions
              },
              {
                path: 'financial_bundles',
                title: 'Financial Bundles',
                component: ClientPortalFinancialBundles,
                permission: Pages.financial_packets
              },
              {
                path: 'users',
                title: 'Users',
                component: ClientPortalUsers,
                permission: Pages.client_portal
              }
            ]
          },
          {
            path: 'salesforce_changes',
            title: 'Salesforce Changes',
            breadcrumbDisabled: true,
            component: SalesforceChanges,
            permission: Pages.salesforce_approval,
            children: [
              {
                index: true,
                component: AccountSalesforceChanges,
                permission: Pages.salesforce_approval
              },
              {
                path: 'account',
                title: 'Account',
                component: AccountSalesforceChanges,
                permission: Pages.salesforce_approval
              },
              {
                path: 'contact',
                title: 'Contact',
                component: ContactSalesforceChanges,
                permission: Pages.salesforce_approval
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/error/403',
    component: Page403
  },
  {
    path: '/error/500',
    component: Page500
  },
  {
    path: '*',
    component: Page404
  }
];

import { removeLocalStorageItem } from './localStorageUtils';
import Cookies from 'js-cookie';

export function signOut(path: string = '/login') {
  try {
    Cookies.remove(process.env.REACT_APP_AUTH_TOKEN as string);
    Cookies.remove(process.env.REACT_APP_AUTH_TOKEN as string, { path: '/', domain: '.kruzeconsulting.com' });
    removeLocalStorageItem(process.env.REACT_APP_ROLE_KEY as string);
    removeLocalStorageItem(process.env.REACT_APP_USER_DATA_KEY as string);
  } catch (e) {
    //
  }

  if (typeof window !== 'undefined') {
    window.location.replace(path);
  }
}

export function signIn(path?: string) {
  if (path && typeof window !== 'undefined') {
    window.location.replace(path);
  }
}

import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { NOTIFICATIONS_FRAGMENT } from '@fragments/notifications';

export const ACCOUNT_GROUP_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  ${NOTIFICATIONS_FRAGMENT}
  fragment AccountGroupFields on AccountGroup {
    accounts
    createdAt
    id
    name
    updatedAt
    user {
      notifications {
        ...NotificationsFields
      }
      email
      id
      photo {
        ...AttachmentFields
      }
    }
  }
`;

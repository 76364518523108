import {
  AccountGroupQuery,
  AccountGroupQueryVariables,
  AccountGroupsQuery,
  AccountGroupsQueryVariables
} from '@queries/accountGroup.generated';
import { gql } from '@apollo/client';
import { ACCOUNT_GROUP_FRAGMENT } from '@fragments/accountGroup';
import { apolloLazyQueryHookGenerator, apolloQueryHookGenerator } from 'utils/hookGenerator';

export const ACCOUNT_GROUPS = gql`
  ${ACCOUNT_GROUP_FRAGMENT}
  query AccountGroups($search: String) {
    accountGroups(search: $search) {
      ...AccountGroupFields
    }
  }
`;

export const ACCOUNT_GROUP = gql`
  ${ACCOUNT_GROUP_FRAGMENT}
  query AccountGroup($id: ID!) {
    accountGroup(id: $id) {
      ...AccountGroupFields
    }
  }
`;

export const useAccountGroupsQuery = apolloQueryHookGenerator<AccountGroupsQuery, AccountGroupsQueryVariables>(
  ACCOUNT_GROUPS,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useAccountGroupsLazyQuery = apolloLazyQueryHookGenerator<AccountGroupsQuery, AccountGroupsQueryVariables>(
  ACCOUNT_GROUPS,
  {
    notifyOnNetworkStatusChange: true
  }
);

export const useAccountGroupQuery = apolloQueryHookGenerator<AccountGroupQuery, AccountGroupQueryVariables>(
  ACCOUNT_GROUP,
  {
    notifyOnNetworkStatusChange: true
  }
);

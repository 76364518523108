import { ClientChartChartData } from 'apollo/generatedSchema';
import { IChartDataViewType } from 'interfaces/chart';
import { ChartOptionsGenerate } from 'utils/chartOptionsGenerate';

const chartColor = new ChartOptionsGenerate();

export const useGenerateChartDatasets = (data?: ClientChartChartData[], dataViewTypes?: IChartDataViewType) => {
  const legends: { name: string; color: string }[] = [];
  const newData: any = {
    labels: [],
    datasets: []
  };

  if (!data) {
    return {
      legends: legends,
      data: newData
    };
  }

  data[0]?.pathData?.forEach(path => {
    newData.labels.push(path.date);
  });

  data?.forEach(item => {
    const color = chartColor.getNextColor();
    legends.push({
      name: item.key!,
      color: `rgba(${color})`
    });

    newData.datasets.push({
      type: dataViewTypes?.[item?.key || ''] || 'bar',
      label: item?.key,
      data: item?.pathData?.map(i => i.value),
      borderColor: `rgba(${color})`,
      backgroundColor: `rgba(${color})`,
      borderRadius: 8
    });
  });

  return {
    legends,
    data: newData
  };
};

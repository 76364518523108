import { useEffect } from 'react';
import { useLazyFetch } from './useLazyFetch';
import Cookies from 'js-cookie';
import { signIn as signInUtil, signOut as signOutUtil } from 'utils/authUtils';
import { setLocalStorageItem } from 'utils/localStorageUtils';

export function useAuth() {
  const [authRequestMaker, { data, error, loading }] = useLazyFetch<any>(
    `${process.env.REACT_APP_AUTH_ENDPOINT}/user_session`,
    {
      method: 'POST'
    }
  );
  const [logoutRequestMaker] = useLazyFetch<any>(`${process.env.REACT_APP_AUTH_ENDPOINT}/user_session`, {
    method: 'POST'
  });

  useEffect(() => {
    if (!error && data) {
      signInUtil('/clients');
    }
  }, [data, error]);

  const signInBasic = async (email: string, password: string) => {
    await authRequestMaker({
      user: {
        email,
        password
      }
    });
  };

  const signInWithAzure = () => {
    window.open(process.env.REACT_APP_AZURE_AUTH_ENDPOINT as string, '_self');
  };

  const signOut = async () => {
    try {
      await logoutRequestMaker(null, {
        method: 'DELETE'
      });
      signOutUtil();
    } catch (e) {
      //
    }
  };

  return {
    loading,
    error,
    signInBasic,
    signOut,
    signInWithAzure
  };
}

export function useIsAuthenticated(): boolean {
  const token = Cookies.get(process.env.REACT_APP_AUTH_TOKEN as string);

  return Boolean(token);
}

export function useIsSessionAlive(): void {
  const isAuthenticated = useIsAuthenticated();
  const [checkSession, { error, data }] = useLazyFetch<{ token: string }>(
    `${process.env.REACT_APP_AUTH_ENDPOINT}/users/me`,
    {
      method: 'GET'
    }
  );

  useEffect(() => {
    if (isAuthenticated) {
      checkSession();
    }
  }, [checkSession, isAuthenticated]);

  useEffect(() => {
    if (error) {
      signOutUtil();
    }
  }, [error]);

  useEffect(() => {
    if ((data as any)?.roles && !error) {
      setLocalStorageItem(process.env.REACT_APP_ROLE_KEY as string, (data as any)?.roles);
    }
    if ((data as any) && !error) {
      const { id, email } = data as any;
      setLocalStorageItem(process.env.REACT_APP_USER_DATA_KEY as string, {
        id,
        email
      });
    }
  }, [data, error]);
}

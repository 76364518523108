export class ChartOptionsGenerate {
  private colorId: number;

  readonly chartColors: string[];

  constructor() {
    this.colorId = -1;
    this.chartColors = [
      '2, 171, 227',
      '255, 206, 118',
      '80, 188, 143',
      '169, 68, 107',
      '100, 57, 105',
      '128, 71, 91',
      '242, 87, 103',
      '255, 160, 0',
      '64, 205, 232',
      '69, 43, 78',
      '255, 128, 93',
      '176, 215, 255',
      '102, 134, 255',
      '249, 146, 114',
      '80, 57, 163'
    ];
  }

  getNextColor = (): string => {
    this.colorId += 1;

    if (this.colorId > this.chartColors.length - 1) {
      return this.randomRgbColor();
    }

    return this.chartColors[this.colorId];
  };

  randomRgbColor = (): string => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    return `${r}, ${g}, ${b}`;
  };
}

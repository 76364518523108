import { useEffect, useMemo, useRef, useState } from 'react';
import { useUsersLazyQuery } from '@queries/user';
import { debounce as _debounce } from 'lodash';
import { FieldSelectOption } from 'types';

export function useDebounceSearchUser(defaultOptions: FieldSelectOption[] = [], labelField?: 'name' | 'email') {
  const abortC = useRef<AbortController>();
  const [getUsers, { data, loading }] = useUsersLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [options, setOptions] = useState<any>(defaultOptions);
  useEffect(() => {
    if (defaultOptions.length) {
      setOptions(defaultOptions ?? []);
    }
  }, [defaultOptions]);

  const onDebounceSearch = useMemo(() => {
    return _debounce((value: string) => {
      if (value) {
        setOptions([]);
        if (abortC.current instanceof AbortController) {
          abortC.current.abort();
        }
        abortC.current = new AbortController();
        getUsers({
          variables: {
            search: value
          },
          context: {
            fetchOptions: {
              signal: abortC.current.signal
            }
          }
        });
      }
    }, 800);
  }, [getUsers]);

  useEffect(() => {
    const userData = data?.users?.data || [];
    if (userData.length) {
      setOptions(
        userData.map(user => ({
          value: user!.id,
          label: labelField ? user![labelField] ?? user!.email : user!.name ?? user!.email
        }))
      );
    }
  }, [data, labelField]);

  return {
    options,
    loading,
    onDebounceSearch
  };
}

import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useIsAuthenticated } from 'hooks';

export const AuthComponentWrapper: React.FunctionComponent<React.PropsWithChildren> = React.memo(({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const client = useApolloClient();
  useEffect(() => {
    if (!isAuthenticated) {
      client.resetStore();
    }
  }, [client, isAuthenticated]);

  return !isAuthenticated ? <Navigate to="/login" /> : <React.Fragment>{children}</React.Fragment>;
});

import React, { useState } from 'react';
import { Controller, useController } from 'react-hook-form';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import { UploadOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  InputProps,
  Radio,
  Row,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  Typography,
  Upload
} from 'antd';
import { CheckboxGroupProps, CheckboxProps } from 'antd/es/checkbox';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { PasswordProps, TextAreaProps } from 'antd/es/input';
import { InputNumberProps } from 'antd/es/input-number';
import { RadioGroupProps } from 'antd/es/radio';
import commonStyles from 'theme/Common.module.less';
import { DraftEditor } from 'components';
import styles from './Fields.module.less';
import cs from 'classnames';
import {
  AppDateFormats,
  FieldCustomSelectOption,
  FieldOptionType,
  FieldSelectOption,
  FileUploadPropsAttrType
} from 'types';
import { convertToBase64Callback } from 'utils/fileUtils';
import { getFieldValidationErrors } from 'utils/validationUtils';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IHookFormController
  extends Required<Pick<UseControllerProps<any>, 'name' | 'control'>>,
    Omit<UseControllerProps<any>, 'name' | 'control'> {}

interface IFieldGeneral {
  className?: string;
  ariaLabel?: string;
  label?: string | React.ReactNode;
  showErrorMessage?: boolean;
  required?: boolean;
}

export interface IFieldInput extends IFieldGeneral, InputProps {}

export type FieldInputType = IFieldInput & IHookFormController;

export const FieldInput: React.FC<FieldInputType> = (props: FieldInputType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    ariaLabel,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <>
                  <Text type="secondary">{label}</Text>
                  {required && <Text type="danger"> *</Text>}
                </>
              ) : (
                label
              ))}
            <Input
              className={cs(className, { [styles.inputError]: !!error })}
              aria-label={ariaLabel}
              {...inputProps}
              {...field}
            />
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

interface IFieldHidden {
  showErrorMessage?: boolean;
}

export type FieldInputHiddenType = IFieldHidden & InputProps & IHookFormController;

export const FieldInputHidden: React.FC<FieldInputHiddenType> = (props: FieldInputHiddenType) => {
  const { name, control, rules, shouldUnregister, showErrorMessage, defaultValue, ...inputProps } = props;
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    rules: rules,
    shouldUnregister: shouldUnregister,
    defaultValue: defaultValue
  });

  return (
    <>
      <Input style={{ display: 'none' }} {...inputProps} {...field} />
      {showErrorMessage &&
        !!error &&
        getFieldValidationErrors(error, name).map((item, index) => (
          <Text key={index} type="danger" className={commonStyles.note}>
            {item}
          </Text>
        ))}
    </>
  );
};

export interface IFieldPassword extends IFieldGeneral, PasswordProps {}

export type FieldPasswordType = IFieldPassword & IHookFormController;

export const FieldPassword: React.FC<FieldPasswordType> = (props: FieldPasswordType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}
            <Input.Password className={cs(className, { [styles.inputError]: !!error })} {...inputProps} {...field} />
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldTextarea extends IFieldGeneral, TextAreaProps {}

export type FieldTextareaType = IFieldTextarea & IHookFormController;

export const FieldTextArea: React.FC<FieldTextareaType> = (props: FieldTextareaType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}
            <TextArea className={cs(className, { [styles.inputError]: !!error })} {...inputProps} {...field} />
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldDraftEditor extends IFieldGeneral {
  toolbarOptions?: any;
  allToolbarOption?: boolean;
}

export type FieldDraftEditorType = IFieldDraftEditor & IHookFormController;

export const FieldDraftEditor: React.FC<FieldDraftEditorType> = (props: FieldDraftEditorType) => {
  const {
    name,
    control,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    toolbarOptions = null,
    allToolbarOption = false
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { ref, ...restFields } = field;
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}
            <DraftEditor
              className={cs(className, { [styles.draftTextError]: !!error })}
              allToolbarOption={allToolbarOption}
              toolbarOptions={toolbarOptions}
              {...restFields}
            />
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldNumber extends IFieldGeneral, InputNumberProps {}

export type FieldNumberType = IFieldNumber & IHookFormController;

export const FieldNumber: React.FC<FieldNumberType> = (props: FieldNumberType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    ariaLabel,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}
            <InputNumber
              aria-label={ariaLabel}
              className={cs(className, styles.numberStyle, { [styles.inputError]: !!error })}
              {...inputProps}
              {...field}
            />
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldRadio extends IFieldGeneral, RadioGroupProps {
  options: FieldOptionType[];
}

export type FieldRadioType = IFieldRadio & IHookFormController;

export const FieldRadio: React.FC<FieldRadioType> = (props: FieldRadioType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    options,
    optionType,
    required,
    ...inputProps
  } = props;

  const ComponentWrapper = optionType === 'button' ? Radio.Button : Radio;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { value, ...restField } = field;
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}
            <Radio.Group className={className} value={value} {...inputProps} {...restField}>
              {(options || []).map(item => {
                if (typeof item === 'string' || typeof item === 'number') {
                  return (
                    <React.Fragment key={item}>
                      <ComponentWrapper value={item} className={cs({ [styles.radioError]: !!error })}>
                        {item}
                      </ComponentWrapper>
                    </React.Fragment>
                  );
                } else {
                  const { label: labelF, value, ...rest } = item;
                  return (
                    <React.Fragment key={value as string}>
                      <ComponentWrapper value={value} className={cs({ [styles.radioError]: !!error })} {...rest}>
                        {labelF}
                      </ComponentWrapper>
                    </React.Fragment>
                  );
                }
              })}
            </Radio.Group>
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldCheckbox extends IFieldGeneral, CheckboxProps {}

export type FieldCheckboxType = IFieldCheckbox & IHookFormController;

export const FieldCheckbox: React.FC<FieldCheckboxType> = (props: FieldCheckboxType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    ariaLabel,
    required,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { value, ...restField } = field;
        return (
          <>
            <Checkbox className={className} checked={!!value} {...inputProps} {...restField}>
              {!!label &&
                (typeof label === 'string' ? (
                  <Text type={!!error ? 'danger' : undefined} aria-label={ariaLabel}>
                    {label} {required ? '*' : ''}
                  </Text>
                ) : (
                  label
                ))}
            </Checkbox>
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldCheckboxGroup extends IFieldGeneral, CheckboxGroupProps {
  options: FieldOptionType[];
}

export type FieldCheckboxGroupType = IFieldCheckboxGroup & IHookFormController;

export const FieldCheckboxGroup: React.FC<FieldCheckboxGroupType> = (props: FieldCheckboxGroupType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    options,
    required,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <Checkbox.Group options={options} className={className} {...inputProps} {...field} />
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldSwitch extends IFieldGeneral, SwitchProps {}

export type FieldSwitchType = IFieldSwitch & IHookFormController;

export const FieldSwitch: React.FC<FieldSwitchType> = (props: FieldSwitchType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { value, ...restField } = field;
        return (
          <>
            <label>
              <Switch className={cs(className, styles.switchPadding)} checked={value} {...inputProps} {...restField} />
              {!!label &&
                (typeof label === 'string' ? (
                  <Text type="secondary">
                    {label} {required ? '*' : ''}
                  </Text>
                ) : (
                  label
                ))}
            </label>
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldSelect extends IFieldGeneral, SelectProps {
  optionsList?: FieldSelectOption[];
  ariaLabel?: string;
  optionAriaLabel?: string;
}

export type FieldSelectType = IFieldSelect & IHookFormController;

export const FieldSelect: React.FC<FieldSelectType> = (props: FieldSelectType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    optionsList,
    required,
    ariaLabel,
    optionAriaLabel,
    ...selectProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <>
                  <Text type="secondary">{label}</Text>
                  {required && <Text type="danger"> *</Text>}
                </>
              ) : (
                label
              ))}
            <Select
              aria-label={ariaLabel}
              className={cs(className, commonStyles.fullWidth, { [styles.selectError]: !!error })}
              {...selectProps}
              {...field}
            >
              {(optionsList || []).map(({ value, label: optionLabel, disabled }) => {
                return (
                  <Option key={String(value)} disabled={!!disabled} value={value} aria-label={optionAriaLabel}>
                    {optionLabel}
                  </Option>
                );
              })}
            </Select>
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export interface IFieldCustomSelect extends IFieldGeneral, SelectProps {
  optionsList?: FieldCustomSelectOption[];
}
export type FieldCustomSelectType = IFieldCustomSelect & IHookFormController;

export const FieldSelectWithCustomOptions: React.FC<FieldCustomSelectType> = (props: FieldCustomSelectType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    optionsList,
    required,
    ...selectProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <>
                  <Text type="secondary">{label}</Text>
                  {required && <Text type="danger"> *</Text>}
                </>
              ) : (
                label
              ))}
            <Select
              className={cs(className, commonStyles.fullWidth, { [styles.selectError]: !!error })}
              {...selectProps}
              {...field}
            >
              {(optionsList || []).map(({ value, label: optionLabel, disabled, creatorName }) => {
                return (
                  <Option value={value} label={optionLabel}>
                    <Row>
                      <Col span={24}>{optionLabel}</Col>
                      <Col span={24}>
                        <Text type="secondary">Creator: {creatorName}</Text>
                      </Col>
                    </Row>
                  </Option>
                );
              })}
            </Select>
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export type FieldDatePickerType = IFieldGeneral & DatePickerProps & IHookFormController;

export const FieldDatePicker: React.FC<FieldDatePickerType> = (props: FieldDatePickerType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    format,
    ariaLabel,
    required,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      aria-label={ariaLabel}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}
            <DatePicker
              className={cs(className, styles.datePickerStyle, { [styles.datePickerError]: !!error })}
              format={format ? format : AppDateFormats.AppStandardDate}
              {...inputProps}
              {...field}
            />
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export type FieldRangePickerType = IFieldGeneral & RangePickerProps & IHookFormController;

export const FieldRangePicker: React.FC<FieldRangePickerType> = (props: FieldRangePickerType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    format,
    required,
    ...inputProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const customError: any = error;
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}
            <RangePicker
              className={cs(className, styles.datePickerStyle, { [styles.datePickerError]: !!error })}
              format={format ? format : AppDateFormats.AppStandardDate}
              {...inputProps}
              {...field}
            />
            {showErrorMessage && !!error && (
              <>
                {Array.isArray(customError)
                  ? customError?.map((err: any, index: number) => {
                      return getFieldValidationErrors(err, name, index === 0 ? 'Start Date' : 'End Date').map(
                        (item, index) => (
                          <Text key={index} type="danger" className={commonStyles.note}>
                            {item}
                          </Text>
                        )
                      );
                    })
                  : getFieldValidationErrors(error, name, label).map((item, index) => (
                      <Text key={index} type="danger" className={commonStyles.note}>
                        {item}
                      </Text>
                    ))}
              </>
            )}
          </>
        );
      }}
    />
  );
};

const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export type FieldUploadType = IFieldGeneral &
  IHookFormController & {
    uploadButton?: React.ReactNode;
    uploadProps?: FileUploadPropsAttrType;
  };

export const FieldUploadFile: React.FC<FieldUploadType> = (props: FieldUploadType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    uploadButton,
    uploadProps
  } = props;

  const { maxCount, ...restUploadProps } = uploadProps as FileUploadPropsAttrType;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error }, ...r }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}

            <div>
              <Upload
                className={className}
                fileList={field?.value?.fileList || []}
                customRequest={dummyRequest}
                maxCount={maxCount}
                {...restUploadProps}
                {...field}
              >
                {((maxCount && (field?.value?.fileList || []).length < maxCount) || !maxCount) && uploadButton}
              </Upload>
            </div>

            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export type FieldUploadSingleType = FieldUploadType & {
  fileType: 'img' | 'other';
};

export const FieldUploadSingleFile: React.FC<FieldUploadSingleType> = (props: FieldUploadSingleType) => {
  const [sourceUrl, setSourceUrl] = useState<string>();

  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    uploadButton,
    fileType
  } = props;

  const renderFile = (url: string) => {
    return fileType === 'img' ? (
      <img src={url} style={{ width: '100px', height: '100px' }} alt="" />
    ) : (
      <embed src={url} style={{ width: '100px', height: '100px' }} />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error }, ...r }) => {
        const { onChange, ...restField } = field;

        const handleChange = (e: any) => {
          field.onChange(e);
          convertToBase64Callback(e.file.originFileObj, url => {
            setSourceUrl(url);
          });
        };

        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}

            <div>
              <Upload
                className={className}
                fileList={field?.value?.fileList || []}
                customRequest={dummyRequest}
                showUploadList={false}
                listType="picture-card"
                {...restField}
                onChange={handleChange}
              >
                {!sourceUrl
                  ? field?.value?.file?.url
                    ? renderFile(field?.value?.file?.url)
                    : uploadButton
                  : renderFile(sourceUrl)}
              </Upload>
            </div>

            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export type FieldDragUploadType = Omit<FieldUploadType, 'uploadButton'> & {
  dragHelperText?: string;
};
export const FieldDragUploadFile: React.FC<FieldDragUploadType> = (props: FieldDragUploadType) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    dragHelperText,
    uploadProps
  } = props;

  const { maxCount, ...restUploadProps } = uploadProps as FileUploadPropsAttrType;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}

            <div>
              <Upload.Dragger
                className={cs(styles.dragFieldContent, {
                  [styles.dragFieldHidden]: !(
                    (maxCount && (field?.value?.fileList || []).length < maxCount) ||
                    !maxCount
                  ),
                  [className!]: !!className
                })}
                fileList={field?.value?.fileList || []}
                customRequest={dummyRequest}
                maxCount={maxCount}
                {...restUploadProps}
                {...field}
              >
                {((maxCount && (field?.value?.fileList || []).length < maxCount) || !maxCount) && (
                  <>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">{dragHelperText}</p>
                  </>
                )}
              </Upload.Dragger>
            </div>

            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export type FieldDragUploadSingleType = FieldDragUploadType & {
  fileType: 'img' | 'other';
};

export const FieldDragUploadSingleFile: React.FC<FieldDragUploadSingleType> = (props: FieldDragUploadSingleType) => {
  const [sourceUrl, setSourceUrl] = useState<string>();

  const {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    className,
    showErrorMessage,
    label,
    required,
    dragHelperText,
    fileType
  } = props;

  const renderFile = (url: string) => {
    return fileType === 'img' ? (
      <img src={url} style={{ width: '100px', height: '100px' }} alt="" />
    ) : (
      <embed src={url} style={{ width: '100px', height: '100px' }} />
    );
  };

  const dragFileButton = (
    <>
      <p className="ant-upload-drag-icon">
        <UploadOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">{dragHelperText}</p>
    </>
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { onChange, ...restField } = field;

        const handleChange = (e: any) => {
          field.onChange(e);
          convertToBase64Callback(e.file.originFileObj, url => {
            setSourceUrl(url);
          });
        };

        return (
          <>
            {!!label &&
              (typeof label === 'string' ? (
                <Text type="secondary">
                  {label} {required ? '*' : ''}
                </Text>
              ) : (
                label
              ))}

            <div>
              <Upload.Dragger
                className={cs(styles.dragFieldContent, className)}
                fileList={field?.value?.fileList || []}
                customRequest={dummyRequest}
                showUploadList={false}
                listType="picture-card"
                {...restField}
                onChange={handleChange}
              >
                {!sourceUrl
                  ? field?.value?.file?.url
                    ? renderFile(field?.value?.file?.url)
                    : dragFileButton
                  : renderFile(sourceUrl)}
              </Upload.Dragger>
            </div>
            {!!field?.value?.file?.name && <Text className={commonStyles.note}>{field?.value?.file?.name}</Text>}
            {showErrorMessage &&
              !!error &&
              getFieldValidationErrors(error, name, label).map((item, index) => (
                <Text key={index} type="danger" className={commonStyles.note}>
                  {item}
                </Text>
              ))}
          </>
        );
      }}
    />
  );
};

export const FieldInputMemo: React.FC<FieldInputType> = React.memo(FieldInput);
export const FieldInputHiddenMemo: React.FC<FieldInputHiddenType> = React.memo(FieldInputHidden);
export const FieldPasswordMemo: React.FC<FieldPasswordType> = React.memo(FieldPassword);
export const FieldTextAreaMemo: React.FC<FieldTextareaType> = React.memo(FieldTextArea);
export const FieldNumberMemo: React.FC<FieldNumberType> = React.memo(FieldNumber);
export const FieldRadioMemo: React.FC<FieldRadioType> = React.memo(FieldRadio);
export const FieldCheckboxMemo: React.FC<FieldCheckboxType> = React.memo(FieldCheckbox);
export const FieldCheckboxGroupMemo: React.FC<FieldCheckboxGroupType> = React.memo(FieldCheckboxGroup);
export const FieldSwitchMemo: React.FC<FieldSwitchType> = React.memo(FieldSwitch);
export const FieldSelectMemo: React.FC<FieldSelectType> = React.memo(FieldSelect);
export const FieldDatePickerMemo: React.FC<FieldDatePickerType> = React.memo(FieldDatePicker);
export const FieldRangePickerMemo: React.FC<FieldRangePickerType> = React.memo(FieldRangePicker);
export const FieldUploadFileMemo: React.FC<FieldUploadType> = React.memo(FieldUploadFile);
export const FieldUploadSingleFileMemo: React.FC<FieldUploadSingleType> = React.memo(FieldUploadSingleFile);
export const FieldDragUploadFileMemo: React.FC<FieldDragUploadType> = React.memo(FieldDragUploadFile);
export const FieldDragUploadSingleFileMemo: React.FC<FieldDragUploadSingleType> = React.memo(FieldDragUploadSingleFile);

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useConnectQboClientM } from '@mutations/client';

export const useConnectQboClient = () => {
  let [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const realmId = searchParams.get('realmId');

  const [connectQboClient] = useConnectQboClientM();

  useEffect(() => {
    if (code && state && realmId) {
      connectQboClient({
        variables: {
          input: {
            attributes: {
              code: code,
              realmId: realmId,
              redirectUri: process.env.REACT_APP_QBO_REDIRECT_URI as string
            }
          }
        },
        onCompleted: data => {
          if (data?.clientQboConnect?.errors) {
            window.location.replace('/error/500');
          } else {
            window.location.replace('/clients');
          }
        }
      });
    } else {
      window.location.replace('/error/500');
    }
  }, [code, state, realmId, connectQboClient]);
};

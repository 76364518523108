import { gql } from '@apollo/client';

export const SHORTCUT_FRAGMENT = gql`
  fragment ShortcutFields on Shortcut {
    clientId
    color
    createdAt
    icon
    id
    link
    name
    updatedAt
  }
`;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAbility } from 'hooks';
import { IUserAbilityResponse } from 'interfaces';
import { Pages } from 'types';

interface IRoutePermissionProps {
  children: React.ReactNode;
  permission: Pages;
  authorization?: (keyof IUserAbilityResponse)[];
}

export const RoutePermissions: React.FC<IRoutePermissionProps> = ({
  children,
  permission,
  authorization = ['canRead']
}: IRoutePermissionProps) => {
  const data = useUserAbility(permission);
  let can = true;
  ([...authorization, 'canRead'] as (keyof IUserAbilityResponse)[])?.forEach(i => {
    if (!data[i]) {
      can = false;
    }
  });
  return !can ? <Navigate to="/error/403" replace={true} /> : <>{children}</>;
};

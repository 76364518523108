import React from 'react';
import { Col } from 'antd';

interface IProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const AppPageContent = React.memo(({ children, className }: IProps) => {
  return (
    <Col span={24} className={className}>
      {children}
    </Col>
  );
});

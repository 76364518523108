import React from 'react';
import { Tooltip } from 'antd';
import { AbstractTooltipProps } from 'antd/es/tooltip';

type IProps = AbstractTooltipProps & {
  authorized: boolean;
  children?: React.ReactNode;
};

export function Authorized({ children, authorized, ...rest }: IProps) {
  return (
    <Tooltip {...rest} title={!authorized ? 'Sorry, you are not authorized' : ''}>
      {children}
    </Tooltip>
  );
}

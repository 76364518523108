import React from 'react';
import { Space, Typography } from 'antd';
import styles from './ChartLegends.module.less';

interface IProps {
  legends?: {
    name: string;
    color: string;
  }[];
}

export function ChartLegends({ legends }: IProps) {
  return (
    <Space wrap>
      {legends?.map((legend, i) => (
        <Space key={legend.name}>
          <div
            className={styles.legendDot}
            style={{
              backgroundColor: legend?.color
            }}
          />
          <Typography.Text className={styles.legend}>{legend.name}</Typography.Text>
        </Space>
      ))}
    </Space>
  );
}

export default ChartLegends;

import { useCallback, useMemo } from 'react';
import { notification } from 'antd';
import {
  INotificationArgs,
  INotificationHook,
  IUseNotificationArgs,
  NStaticFn,
  NotificationTypes
} from 'interfaces/notifications';

export function useNotifications(hookArgs?: IUseNotificationArgs): INotificationHook {
  const { key, placement, className, actionType = 'open' } = hookArgs || {};

  const openNotification = useCallback(
    (args: INotificationArgs) => {
      const {
        key: forcedKey,
        placement: forcedPlacement,
        className: forcedClassName,
        actionType: forcedActionType,
        ...rest
      } = args;

      let localKey = forcedKey ?? key;
      let localPlacement = forcedPlacement ?? placement;
      let localClassName = forcedClassName ?? className;
      let localType: NotificationTypes = forcedActionType ?? actionType;

      const f = notification[localType] as NStaticFn;
      f({
        className: localClassName,
        placement: localPlacement,
        key: localKey,
        ...rest
      });
    },
    [key, placement, className, actionType]
  );

  const closeNotification = useCallback(
    (closeKey?: string) => {
      if (!!closeKey || !!key) {
        notification.destroy(closeKey ?? (key as string));
      }
    },
    [key]
  );

  return useMemo(() => {
    return {
      openNotification,
      closeNotification
    };
  }, [openNotification, closeNotification]);
}

import {
  VendorAnalysesQuery,
  VendorAnalysesQueryVariables,
  VendorsSearchQuery,
  VendorsSearchQueryVariables
} from '@queries/vendorAnalysis.generated';
import { gql } from '@apollo/client';
import { VENDOR_ANALYSIS_FRAGMENT } from '@fragments/vendorAnalysis';
import { apolloLazyQueryHookGenerator, apolloQueryHookGenerator } from 'utils/hookGenerator';

export const VENDOR_ANALYSES = gql`
  ${VENDOR_ANALYSIS_FRAGMENT}
  query VendorAnalyses($page: Int, $per: Int) {
    vendorAnalyses(page: $page, per: $per) {
      data {
        ...VendorAnalysisFields
      }
      currentPage
      per
      totalCount
      totalPages
    }
  }
`;

export const useVendorAnalysesQuery = apolloQueryHookGenerator<VendorAnalysesQuery, VendorAnalysesQueryVariables>(
  VENDOR_ANALYSES,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const VENDOR_SEARCH = gql`
  query VendorsSearch($search: String!) {
    vendorsSearch(search: $search)
  }
`;

export const useVendorSearchLazyQuery = apolloLazyQueryHookGenerator<VendorsSearchQuery, VendorsSearchQueryVariables>(
  VENDOR_SEARCH,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

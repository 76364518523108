import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useRedirectToErrorPage() {
  let navigate = useNavigate();

  return useCallback(
    (code?: 404 | 403 | 500) => {
      switch (code) {
        case 403:
          navigate('/error/403', {
            replace: true
          });
          break;
        case 500:
          navigate('/error/500', {
            replace: true
          });
          break;
        case 404:
        default:
          navigate('/error/404', {
            replace: true
          });
          break;
      }
    },
    [navigate]
  );
}

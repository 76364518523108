import React from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import styles from './AppColoredTitle.module.less';
import cs from 'classnames';

interface IAppTitle extends TitleProps {
  className?: string;
  color?: 'default' | 'blue';
}

export function AppColoredTitle({ children, className, color = 'blue', ...titleProps }: IAppTitle) {
  return (
    <Typography.Title
      {...titleProps}
      className={cs(styles.AppTitle, {
        [className!]: !!className,
        [styles.appTitleKruzeBlue]: color === 'blue'
      })}
    >
      {children}
    </Typography.Title>
  );
}

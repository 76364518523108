import {
  ClientCpQuestionsQuery,
  ClientCpQuestionsQueryVariables,
  NAndQTopicsQuery,
  NAndQTopicsQueryVariables,
  QuestionQuery,
  QuestionQueryVariables,
  QuestionTopicsQuery,
  QuestionTopicsQueryVariables,
  QuestionsFilterQuery,
  QuestionsFilterQueryVariables
} from '@queries/question.generated';
import { gql } from '@apollo/client';
import { QUESTION_FRAGMENT } from '@fragments/question';
import { ANSWER_FRAGMENT } from '@fragments/questions';
import { apolloLazyQueryHookGenerator, apolloQueryHookGenerator } from 'utils/hookGenerator';

export const N_AND_Q_TOPICS = gql`
  query NAndQTopics {
    nAndQTopics
  }
`;

export const QUESTION = gql`
  ${QUESTION_FRAGMENT}
  query Question($id: ID!) {
    clientPortalQuestion(id: $id) {
      ...QuestionFields
    }
  }
`;

export const QUESTION_TOPICS = gql`
  query QuestionTopics($id: ID!) {
    questionTopics(id: $id)
  }
`;

export const QUESTIONS_FILTER = gql`
  ${ANSWER_FRAGMENT}
  query QuestionsFilter($clientId: ID!, $params: FindQuestionsInput!) {
    clientPortalQuestionsFilter(clientId: $clientId, params: $params) {
      answers {
        ...AnswerFields
      }
      closePeriod
      content
      id
      status
      topic
    }
  }
`;

export const CLIENT_CLIENT_PORTAL_QUESTIONS = gql`
  query ClientCpQuestions($id: ID!) {
    client(id: $id) {
      id
      clientPortalQuestions {
        ...QuestionFields
      }
    }
  }
`;

export const useNAndQTopicsQuery = apolloQueryHookGenerator<NAndQTopicsQuery, NAndQTopicsQueryVariables>(
  N_AND_Q_TOPICS,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useQuestionLazyQuery = apolloLazyQueryHookGenerator<QuestionQuery, QuestionQueryVariables>(QUESTION, {
  fetchPolicy: 'network-only'
});

export const useQuestionTopicsQuery = apolloQueryHookGenerator<QuestionTopicsQuery, QuestionTopicsQueryVariables>(
  QUESTION_TOPICS,
  {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  }
);

export const useQuestionFiltersLazyQuery = apolloLazyQueryHookGenerator<
  QuestionsFilterQuery,
  QuestionsFilterQueryVariables
>(QUESTIONS_FILTER, {
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true
});

export const useClientCpQuestionsQuery = apolloQueryHookGenerator<
  ClientCpQuestionsQuery,
  ClientCpQuestionsQueryVariables
>(CLIENT_CLIENT_PORTAL_QUESTIONS, {
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true
});

import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';

export const COMMENT_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  fragment CommentFields on QuestionComment {
    attachments {
      ...AttachmentFields
    }
    body
    commentableType
    createdAt
    id
    seen
    side
    updatedAt
    user {
      id
      email
      name
      photo {
        ...AttachmentFields
      }
    }
  }
`;

import React, { useMemo } from 'react';
import { AppSpin, FieldSelectType } from 'components';
import { SuggestionFields } from 'apollo/generatedSchema';
import { FieldSelect } from './Fields';
import { useDebounceSearchGlSuggestion } from 'hooks';

type IGlSuggestionField = FieldSelectType & {
  glField: SuggestionFields;
};

export function GlSuggestionFieldComponent(props: IGlSuggestionField) {
  const { glField, ...restProps } = props;

  const { options, loading, onDebounceSearch } = useDebounceSearchGlSuggestion(glField);
  const loadingComponent = useMemo(() => <AppSpin size="small" />, []);

  return (
    <FieldSelect
      notFoundContent={loading ? loadingComponent : null}
      options={options}
      onSearch={onDebounceSearch}
      {...(restProps as IGlSuggestionField)}
    />
  );
}

export const GlSuggestionField = React.memo(GlSuggestionFieldComponent);

import {
  LazyQueryHookOptions,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from '@apollo/client';
import { DocumentNode, TypedDocumentNode } from '@apollo/client/core';
import { SubscriptionHookOptions } from '@apollo/client/react/types/types';

export function apolloQueryHookGenerator<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  additionalOptions?: Omit<QueryHookOptions<TData, TVariables>, 'query'>
) {
  return (baseOptions?: Omit<QueryHookOptions<TData, TVariables>, 'query'>) => {
    const options = {
      ...additionalOptions,
      ...baseOptions
    };
    return useQuery<TData, TVariables>(query, options);
  };
}

export function apolloLazyQueryHookGenerator<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  additionalOptions?: Omit<LazyQueryHookOptions<TData, TVariables>, 'query'>
) {
  return (baseOptions?: LazyQueryHookOptions<TData, TVariables>) => {
    const options = {
      ...additionalOptions,
      ...baseOptions
    };
    return useLazyQuery<TData, TVariables>(query, options);
  };
}

export function apolloMutationHookGenerator<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  additionalOptions?: Omit<MutationHookOptions<TData, TVariables>, 'mutation'>
) {
  return (baseOptions?: MutationHookOptions<TData, TVariables>) => {
    const options = {
      ...additionalOptions,
      ...baseOptions
    };
    return useMutation<TData, TVariables>(mutation, options);
  };
}

export function apolloSubscriptionHookGenerator<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  additionalOptions?: Omit<SubscriptionHookOptions<TData, TVariables>, 'subscription'>
) {
  return (baseOptions?: SubscriptionHookOptions<TData, TVariables>) => {
    const options = {
      ...additionalOptions,
      ...baseOptions
    };
    return useSubscription<TData, TVariables>(subscription, options);
  };
}

/*export function useBillPayInformationSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<BillPayInformationSaveMutation, BillPayInformationSaveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillPayInformationSaveMutation, BillPayInformationSaveMutationVariables>(
    BillPayInformationSaveDocument,
    options
  );
}
export type BillPayInformationSaveMutationHookResult = ReturnType<typeof useBillPayInformationSaveMutation>;
export type BillPayInformationSaveMutationResult = Apollo.MutationResult<BillPayInformationSaveMutation>;
export type BillPayInformationSaveMutationOptions = Apollo.BaseMutationOptions<
  BillPayInformationSaveMutation,
  BillPayInformationSaveMutationVariables
>;*/

import React from 'react';
import { AppSpin } from '../appLoading';

interface IProps {
  children: React.ReactNode;
  component?: React.ComponentType | null;
}

export const RouteWrapper: React.FC<IProps> = ({ children, component }: IProps) => {
  const FallbackComponent = component ? component : AppSpin;
  return <React.Suspense fallback={<FallbackComponent />}>{children}</React.Suspense>;
};

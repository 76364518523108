import { gql } from '@apollo/client';

export const NOTIFICATIONS_FRAGMENT = gql`
  fragment NotificationsFields on NotificationPaginated {
    currentPage
    per
    totalCount
    totalPages
    data {
      content
      createdAt
      id
      isDone
      kind
      title
      updatedAt
    }
  }
`;

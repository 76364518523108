export enum Permissions {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum Pages {
  site_administration = 'site_administration',
  account_details = 'account_details',
  budget_and_fees = 'budget_and_fees',
  billing_and_administration = 'billing_and_administration',
  salesforce_approval = 'salesforce_approval',
  payroll_connection = 'payroll_connection',
  tax_information = 'tax_information',
  financial_packets = 'financial_packets',
  administration_1099 = 'administration_1099',
  plaid = 'plaid',
  reporting = 'reporting',
  m_d_and_a = 'm_d_and_a',
  financial_modelling = 'financial_modelling',
  notes_and_questions = 'notes_and_questions',
  r_and_d = 'r_and_d',
  client_portal = 'client_portal',
  client_report = 'client_report',
  vendor_reporting = 'vendor_reporting',
  user_administration = 'user_administration',
  kruze_keeper = 'kruze_keeper',
  development = 'development',
  excel_css = 'excel_css',
  fee_increase_report = 'fee_increase_report',
  client_analysis = 'client_analysis'
}

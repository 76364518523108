import { accountTypePolicy } from './account';
import { clientTypePolicy } from './client';
import { clientAnalysisTypePolicy } from './clientAnalysis';
import { queryTypePolicy } from './query';
import { InMemoryCacheConfig } from '@apollo/client';

/*add more configs here for every feature*/
const typePolicies = mergeTypePolicies([
  queryTypePolicy,
  clientTypePolicy,
  accountTypePolicy,
  clientAnalysisTypePolicy
  //Add more for every query
]);

function mergeTypePolicies(configs?: InMemoryCacheConfig[]) {
  let typePolicies = {};
  (configs || []).forEach((config: InMemoryCacheConfig) => {
    if (Object.values(config.typePolicies || {}).length) {
      typePolicies = { ...typePolicies, ...config.typePolicies };
    }
  });
  return typePolicies;
}

export default typePolicies;

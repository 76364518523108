import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { extraBreadcrumbsHandler, replaceableBreadcrumbsHandler } from '@reactiveVars/appBreadcrumbs';
import styles from './AppBreadcrumbs.module.less';
import { useReactiveVar } from '@apollo/client';
import { IBreadcrumb } from 'interfaces/appBreadcrumbs';
import { linearRouteData } from 'routes';

interface IBreadcrumbsProps {
  extraBreadcrumbs?: IBreadcrumb[];
  replaceableBreadcrumbs?: IBreadcrumb[];
}

interface IRouteBreadcrumbItems {
  [key: string]: IBreadcrumb;
}

const replaceBreadcrumbs = (breadcrumbs: IRouteBreadcrumbItems, replaceWith?: IBreadcrumb[]) => {
  if ((replaceWith || []).length) {
    replaceWith!.forEach(item => {
      if (breadcrumbs[item.path]) {
        breadcrumbs[item.path].title = item.title;
        breadcrumbs[item.path].isDisabled = !!item.isDisabled;
      }
    });
  }
};

const addExtraBreadcrumbs = (breadcrumbs: IRouteBreadcrumbItems, extraBreadcrumbs?: IBreadcrumb[]) => {
  if ((extraBreadcrumbs || []).length) {
    extraBreadcrumbs!.forEach(item => {
      breadcrumbs[item.path] = { path: item.path, title: item.title, isDisabled: !!item.isDisabled };
    });
  }
};

export const AppBreadcrumbs: React.FC<IBreadcrumbsProps> = React.memo(
  ({ extraBreadcrumbs, replaceableBreadcrumbs }: IBreadcrumbsProps) => {
    const location = useLocation();

    const extraBreadcrumbsReactiveVar = useReactiveVar(extraBreadcrumbsHandler);
    const replaceableBreadcrumbsReactiveVar = useReactiveVar(replaceableBreadcrumbsHandler);

    const pathSnippets = location.pathname.split('/').filter(i => i);
    const routeBreadcrumbItems: IRouteBreadcrumbItems = {};
    pathSnippets.forEach((_, index) => {
      const path = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      for (let pattern in linearRouteData) {
        if (
          matchPath(
            {
              path: pattern
            },
            path
          )
        ) {
          routeBreadcrumbItems[path] = {
            path,
            ...linearRouteData[pattern]
          };
          return;
        }
      }
    });
    let breadcrumbItems: IRouteBreadcrumbItems = {
      ...{ '/': { path: '/', title: 'Home', isDisabled: false } },
      ...routeBreadcrumbItems
    };

    replaceBreadcrumbs(breadcrumbItems, replaceableBreadcrumbs);
    replaceBreadcrumbs(breadcrumbItems, replaceableBreadcrumbsReactiveVar);

    addExtraBreadcrumbs(breadcrumbItems, extraBreadcrumbs);
    addExtraBreadcrumbs(breadcrumbItems, extraBreadcrumbsReactiveVar);

    const length = Object.values(breadcrumbItems)?.length;

    const breadcrumbRoutes = Object.values(breadcrumbItems)?.map((item, index) => ({
      title:
        item.isDisabled || index === length - 1 ? (
          <span className={styles.inactiveBreadcrumb}>{item.title}</span>
        ) : (
          <Link to={item.path}>{item.title}</Link>
        ),
      key: item.path
    }));

    return <Breadcrumb items={breadcrumbRoutes as any} />;
  }
);

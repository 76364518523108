import { ClientPlaidItem, ServiceRateChange } from '../generatedSchema';
import { InMemoryCacheConfig } from '@apollo/client';

export const clientTypePolicy: InMemoryCacheConfig = {
  typePolicies: {
    Client: {
      fields: {
        plaidItemsCount: {
          read(existing, { readField }) {
            const clientPlaidItems: Readonly<ClientPlaidItem[] | undefined> = readField('clientPlaidItems');
            return (clientPlaidItems || []).length;
          }
        },
        serviceRateChange: {
          read(existing, { readField, variables }) {
            const serviceRateChanges: Readonly<ServiceRateChange[] | undefined> = readField('serviceRateChanges');
            if (variables?.serviceChangeRateId && serviceRateChanges) {
              return (serviceRateChanges || []).find(item => item.id === variables.serviceChangeRateId);
            }
            return null;
          }
        },
        financialBundles: {
          keyArgs: false
        }
      }
    }
  }
};

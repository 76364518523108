import React from 'react';
import { Button, Drawer, Space } from 'antd';
import { DrawerProps } from 'antd/es';
import { AppColoredTitle, PrimaryGhostButton } from 'components';
import styles from './styles/AppDrawer.module.less';
import cs from 'classnames';

interface IProps {
  children: React.ReactNode;
  visible: boolean;
  loading?: boolean;
  onClose?: (e?: any) => void;
  onSave?: (e?: any) => void;
  title?: string;
  className?: string;
  cancelText?: string;
  saveText?: string;
  showCancelBtn?: boolean;
  showSaveBtn?: boolean;
  saveAttributes?: {};
}

export function AppDrawer({
  children,
  visible,
  onClose,
  onSave,
  title,
  className,
  cancelText,
  saveText,
  loading,
  saveAttributes = {},
  showCancelBtn = true,
  showSaveBtn = true,
  ...rest
}: IProps & DrawerProps) {
  const handleSave = (e: any) => {
    if (onSave) {
      onSave(e);
    }
  };

  const handleClose = (e: any) => {
    if (onClose) {
      onClose(e);
    }
  };

  return (
    <Drawer
      rootClassName={cs(styles.drawer, {
        [className!]: !!className
      })}
      title={
        <AppColoredTitle level={4} rootClassName={styles.drawerTitle}>
          {title}
        </AppColoredTitle>
      }
      open={visible}
      onClose={handleClose}
      {...(showCancelBtn || showSaveBtn
        ? {
            footer: (
              <Space size="middle" rootClassName={styles.drawerButtons}>
                {showCancelBtn && (
                  <PrimaryGhostButton onClick={handleClose}>{cancelText ? cancelText : 'Cancel'}</PrimaryGhostButton>
                )}
                {showSaveBtn && (
                  <Button type="primary" {...saveAttributes} onClick={handleSave} loading={loading}>
                    {saveText ? saveText : 'Save'}
                  </Button>
                )}
              </Space>
            )
          }
        : {})}
      {...rest}
    >
      {children}
    </Drawer>
  );
}
